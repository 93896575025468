import {
  PersonOutline,
  VerifiedOutlined,
  VerifiedUserOutlined,
} from "@mui/icons-material";
import {
  Divider,
  Grid,
  Link,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { ReactElement } from "react";
import { PMBContact, Schema } from "../client/types";
import { isInternalPMB, position } from "../constants/contactPositions";
import { userStatus } from "../constants/userStatus";
import {
  BlockContactDialog,
  InviteContactDialog,
  ResendInviteDialog,
  UnblockContactDialog,
} from "./contact-dialogs";
import { DeactivateContactDialog } from "./deactivate-contact-dialog";
import { DummyRows } from "./dummy-rows";
import { InfoDialog } from "./info-dialog";
import { DialogState, MoreMenu, MoreMenuDialogItem } from "./more-menu";
import { useUserHasRole } from "./role-guard";
import {
  TableCellLink,
  TableColumnHeadCell,
  TableRowHeadCell,
} from "./table-elements";
import { Pageable } from "./use-pageable";
import { useUserInfo } from "./user-info-context";
import { ViewTable } from "./view-table";
import { ViewTablePagination } from "./view-table-pagination";

type RenderEditContactDialogFn = (
  props: { contact: Schema["Contact"] } & DialogState,
) => ReactElement;

type RenderAssignPrimaryContactDialogFn = (
  props: { contact: Schema["Contact"] } & DialogState,
) => ReactElement;

function ContactCell(props: PMBContact & { isPrimaryContact?: boolean }) {
  const isInternal = isInternalPMB(props.position);
  const { isPrimaryContact } = props;

  return (
    <TableRowHeadCell>
      <Grid
        container
        alignItems="center"
        gap={4}
        flexWrap="nowrap"
        minWidth={0}
      >
        <Grid item padding={4} flexShrink={0} flexGrow={0}>
          {isInternal ? (
            <Tooltip title="PMB staff member">
              <VerifiedUserOutlined />
            </Tooltip>
          ) : isPrimaryContact ? (
            <Tooltip title="Primary contact">
              <VerifiedOutlined />
            </Tooltip>
          ) : (
            <PersonOutline />
          )}
        </Grid>

        <Grid item flexGrow={1} minWidth={0}>
          <Typography variant="body2" fontWeight="bold" component="span">
            {props.fullName}
          </Typography>
          <br />
          <Link
            variant="caption"
            underline="hover"
            href={`tel:${props.mobile}`}
          >
            {props.mobile}
          </Link>
          <br />
          <Link
            variant="caption"
            underline="hover"
            href={`mailto:${props.email}`}
          >
            {props.email}
          </Link>
        </Grid>
      </Grid>
    </TableRowHeadCell>
  );
}

function PositionCell(props: { position: PMBContact["position"] }) {
  return <TableCell>{position.format(props.position)}</TableCell>;
}

function UserStatusCell(props: { userStatus?: PMBContact["userStatus"] }) {
  return <TableCell>{userStatus.format(props.userStatus)}</TableCell>;
}

function ContactInviteAction(props: {
  contact: PMBContact;
  onSuccess: () => void;
}) {
  const dialogProps = {
    contact: props.contact,
    onSuccess: props.onSuccess,
  };

  switch (props.contact.userStatus) {
    case "ACTIVE": {
      return (
        <MoreMenuDialogItem
          renderDialog={(dialogState) => (
            <BlockContactDialog {...dialogState} {...dialogProps} />
          )}
        >
          Block
        </MoreMenuDialogItem>
      );
    }

    case "BLOCKED": {
      return (
        <MoreMenuDialogItem
          renderDialog={(dialogState) => (
            <UnblockContactDialog {...dialogState} {...dialogProps} />
          )}
        >
          Unblock
        </MoreMenuDialogItem>
      );
    }

    case "INVITED": {
      return (
        <MoreMenuDialogItem
          renderDialog={(dialogState) => (
            <ResendInviteDialog {...dialogState} {...dialogProps} />
          )}
        >
          Resend invite
        </MoreMenuDialogItem>
      );
    }

    case "NOT_INVITED": {
      return (
        <MoreMenuDialogItem
          renderDialog={(dialogState) => (
            <InviteContactDialog {...dialogState} {...dialogProps} />
          )}
        >
          Invite
        </MoreMenuDialogItem>
      );
    }

    default: {
      return null;
    }
  }
}

function ActionsCell(props: {
  contact: PMBContact;
  onSuccess: () => void;
  renderEditContactDialog: RenderEditContactDialogFn;
  renderAssignPrimaryContactDialog?: RenderAssignPrimaryContactDialogFn;
  canBecomePrimaryContact?: boolean;
}) {
  const {
    contact,
    renderEditContactDialog,
    renderAssignPrimaryContactDialog,
    canBecomePrimaryContact,
  } = props;

  const userCanViewActions = useUserHasRole([
    "SYSTEM_ADMIN",
    "PMB_ADMIN",
    "PMB_VIEWER",
    "COMPANY_ADMIN",
    "LOCATION_ADMIN",
  ]);

  const info = useUserInfo();
  const isCurrentUser = info.principal.id === props.contact.id;
  const userHasFullAccess = useUserHasRole([
    "SYSTEM_ADMIN",
    "PMB_ADMIN",
    "COMPANY_ADMIN",
    "LOCATION_ADMIN",
  ]);

  // Even with full access, user cannot delete themselves from here
  const userCanDelete = userHasFullAccess && !isCurrentUser;

  return (
    <TableCell align="right">
      {userCanViewActions && (
        <MoreMenu>
          <MoreMenuDialogItem
            renderDialog={(dialogProps) => {
              return renderEditContactDialog({ contact, ...dialogProps });
            }}
          >
            Edit
          </MoreMenuDialogItem>

          <ContactInviteAction {...props} />

          {userHasFullAccess &&
            renderAssignPrimaryContactDialog &&
            canBecomePrimaryContact && (
              <MoreMenuDialogItem
                renderDialog={(dialogProps) => {
                  return renderAssignPrimaryContactDialog({
                    contact,
                    ...dialogProps,
                  });
                }}
              >
                Assign as primary
              </MoreMenuDialogItem>
            )}

          <Divider />

          {userCanDelete ? (
            <MoreMenuDialogItem
              renderDialog={(dialogProps) => {
                return <DeactivateContactDialog {...dialogProps} {...props} />;
              }}
            >
              Deactivate
            </MoreMenuDialogItem>
          ) : (
            <MoreMenuDialogItem
              sx={{ opacity: 0.4 }}
              renderDialog={(dialogProps) => {
                return (
                  <InfoDialog
                    {...dialogProps}
                    title="Cannot deactivate your own contact"
                    description="If you want to deactivate your account, please do so from your profile page."
                  />
                );
              }}
            >
              Deactivate
            </MoreMenuDialogItem>
          )}
        </MoreMenu>
      )}
    </TableCell>
  );
}

function EmptyCell() {
  return <TableCell />;
}

/**
 * ContactTable is a stateless component which renders a list of
 * contacts if passed, otherwise a pending state of dummy data.
 */
export function ContactTable(props: {
  contacts?: { totalElements?: number; content: PMBContact[] };
  minWidth?: number;
  pageable: Pageable;
  revalidateView: () => void;

  /**
   * There are differences between how the edit contact dialog should
   * behave between the different views in which the contact table
   * is used. To avoid excessive prop-drilling, we allow the consumer
   * to access the dialog directory via a render prop.
   */
  renderEditContactDialog: RenderEditContactDialogFn;

  renderAssignPrimaryContactDialog?: RenderAssignPrimaryContactDialogFn;

  getIsPrimaryContact?: (contact: PMBContact) => boolean;

  getCanBecomePrimaryContact?: (contact: PMBContact) => boolean;

  columnsConfig: {
    contact: string;
    position: string;
    location: string | null;
    company: string | null;
    status: string;
  };
}) {
  const { minWidth, contacts, pageable, columnsConfig } = props;

  const ROW_HEIGHT = 100;

  return (
    <ViewTable
      minWidth={minWidth}
      head={
        <TableRow>
          <TableColumnHeadCell width={columnsConfig.contact}>
            Contact
          </TableColumnHeadCell>
          <TableColumnHeadCell width={columnsConfig.position}>
            Position
          </TableColumnHeadCell>
          {columnsConfig.location && (
            <TableColumnHeadCell width={columnsConfig.location}>
              Location
            </TableColumnHeadCell>
          )}
          {columnsConfig.company && (
            <TableColumnHeadCell width={columnsConfig.company}>
              Company
            </TableColumnHeadCell>
          )}
          <TableColumnHeadCell width={columnsConfig.status}>
            Status
          </TableColumnHeadCell>
          <TableColumnHeadCell width="auto" />
        </TableRow>
      }
      body={
        !contacts ? (
          <DummyRows
            columns={6}
            rows={pageable.pageSize}
            rowHeight={ROW_HEIGHT}
          />
        ) : (
          contacts.content.map((contact) => {
            const location =
              contact.locationId && contact.locationName
                ? { id: contact.locationId, name: contact.locationName }
                : null;

            const company =
              contact.companyId && contact.companyName
                ? { id: contact.companyId, name: contact.companyName }
                : null;

            const canBecomePrimaryContact =
              props.getCanBecomePrimaryContact?.(contact);

            return (
              <TableRow key={contact.id} sx={{ height: ROW_HEIGHT }} hover>
                <ContactCell
                  {...contact}
                  isPrimaryContact={props.getIsPrimaryContact?.(contact)}
                />
                <PositionCell position={contact.position} />

                {columnsConfig.location &&
                  (location ? (
                    <TableCellLink to={`/location/${location.id}/profile`}>
                      {location.name}
                    </TableCellLink>
                  ) : (
                    <EmptyCell />
                  ))}

                {columnsConfig.company &&
                  (company ? (
                    <TableCellLink to={`/company/${company.id}/profile`}>
                      {company.name}
                    </TableCellLink>
                  ) : (
                    <EmptyCell />
                  ))}

                <UserStatusCell userStatus={contact.userStatus} />
                <ActionsCell
                  {...props}
                  contact={contact}
                  onSuccess={props.revalidateView}
                  canBecomePrimaryContact={canBecomePrimaryContact}
                />
              </TableRow>
            );
          })
        )
      }
      pagination={
        <ViewTablePagination
          {...pageable.paginationProps}
          totalElements={contacts?.totalElements}
        />
      }
    />
  );
}
