import {
  Alert,
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { boolean, number, object, string } from "yup";
import { unpackResponse, useClient, useClientSWR } from "../../client";
import { Schema } from "../../client/types";
import {
  countryFormatter,
  countryRequiresState,
} from "../../constants/countries";
import { marketSegment } from "../../constants/marketSegments";
import {
  getStatesOrProvincesByCountry,
  stateFormatter,
} from "../../constants/statesAndProvinces";
import { caughtValueToString } from "../../utils/caught-error";
import { serializeAddress } from "../../utils/serialize-address";
import { state, zipCode } from "../../utils/yup-validators";
import {
  AsyncAutocompleteSelectField,
  getAutocompleteSelectFieldProps,
} from "../async-autocomplete-select-field";
import {
  AutocompleteSelectField,
  contactToOption,
} from "../autocomplete-select-field";
import { CheckboxField, getCheckboxFieldProps } from "../checkbox-field";
import { DateField, getDateFieldProps } from "../date-field";
import { NumberField, getNumberFieldProps } from "../number-field";
import { getTextFieldProps } from "../text-field";
import { useInternalStaffLists } from "../use-internal-staff-lists";
import { ViewHeader } from "../view-header";

type AutocompleteOption = {
  value: number;
  label: string;
};

function InputsStack(props: { children: ReactNode }) {
  return (
    <Stack gap={6} paddingTop={4}>
      {props.children}
    </Stack>
  );
}

function FormSection(props: { heading: string; children: ReactNode }) {
  return (
    <div>
      <Typography variant="h5" component="h2">
        {props.heading}
      </Typography>

      {props.children}
    </div>
  );
}

type LocationCreateFormValues = {
  name: string;
  customerNumber: string;

  // Address
  addressLineOne: string;
  addressLineTwo: string;
  addressCity: string;
  addressState: Schema["StateCodeEnum"];
  addressZipCode: string;
  addressCountry: Schema["CountryCodeEnum"];

  // Deal
  salesDate: string;
  marketSegment: Schema["MarketSegment"];
  hiltonInnCode: string;
  salesOrderId: string;
  dealValue: number;
  isSelfInstall: boolean;

  // Installation & Opening
  salesRep: AutocompleteOption | null;
  projectManager: AutocompleteOption | null;
  installer: AutocompleteOption | null;
  grandOpeningDate: string;
  installDate: string;
  softOpeningDate: string;

  // Billing Company
  createNewCompany: boolean;
  companyName: string;
  company: AutocompleteOption | null;

  useLocationAddress: boolean;
  companyAddressLineOne: string;
  companyAddressLineTwo: string;
  companyAddressCity: string;
  companyAddressState: Schema["StateCodeEnum"];
  companyAddressZipCode: string;
  companyAddressCountry: Schema["CountryCodeEnum"];
};

function getInitialValues(): LocationCreateFormValues {
  return {
    name: "",
    customerNumber: "",

    addressLineOne: "",
    addressLineTwo: "",
    addressCity: "",
    addressState: "N_A",
    addressZipCode: "",
    addressCountry: "USA",

    companyAddressLineOne: "",
    companyAddressLineTwo: "",
    companyAddressCity: "",
    companyAddressState: "N_A",
    companyAddressZipCode: "",
    companyAddressCountry: "USA",

    salesRep: null,
    projectManager: null,
    installer: null,

    salesDate: "",
    marketSegment: "RESTAURANT_BAR_TAPROOM",
    hiltonInnCode: "",
    salesOrderId: "",
    dealValue: 0,
    isSelfInstall: false,
    grandOpeningDate: "",
    installDate: "",
    softOpeningDate: "",

    createNewCompany: true,
    companyName: "",
    company: null,
    useLocationAddress: true,
  };
}

function LocationForm(props: {
  installers?: Array<Schema["Contact"]>;
  projectManagers?: Array<Schema["Contact"]>;
  salesReps?: Array<Schema["Contact"]>;
  companies?: Array<Schema["Company"]>;
}) {
  const client = useClient();
  const navigate = useNavigate();
  const formik = useFormik<LocationCreateFormValues>({
    initialValues: getInitialValues(),
    validationSchema: object().shape({
      name: string().required("Location name is required."),
      customerNumber: string().required("Customer number is required."),

      addressLineOne: string().required("Address line 1 is required"),
      addressLineTwo: string(),
      addressCity: string().required("City is required."),
      addressState: state({ countryField: "addressCountry" }),
      addressZipCode: zipCode().required("ZIP code is required."),
      addressCountry: string().required("Country is required."),

      salesRep: object()
        .shape({ value: number() })
        .required("Sales rep is required."),

      projectManager: object()
        .shape({ value: number() })
        .required("Project manager is required."),

      marketSegment: string().required("Market segment is required."),
      companyName: string().when("createNewCompany", {
        is: true,
        then: (schema) => schema.required("Company name is required."),
      }),
      company: object()
        .shape({ value: number() })
        .nullable()
        .when("createNewCompany", {
          is: false,
          then: (schema) => schema.required("Company must be selected."),
        }),

      companyAddressLineOne: string().when("useLocationAddress", {
        is: false,
        then: (s) => s.required("Address line 1 is required"),
      }),
      companyAddressLineTwo: string(),
      companyAddressCity: string().when("useLocationAddress", {
        is: false,
        then: (s) => s.required("City is required"),
      }),
      companyAddressState: state({ countryField: "companyAddressCountry" }),
      companyAddressZipCode: zipCode().when("useLocationAddress", {
        is: false,
        then: (s) => s.required("ZIP code is required."),
      }),
      companyAddressCountry: string().when("useLocationAddress", {
        is: false,
        then: (s) => s.required("Country is required."),
      }),
      dealValue: number()
        .min(1, "Deal value must be at least $1.")
        .required("Deal value is required"),

      isSelfInstall: boolean(),
      installer: object()
        .shape({ value: number() })
        .nullable()
        .when("isSelfInstall", {
          is: false,
          then: (s) => s.required("Installer is required."),
        }),
      salesDate: string().required("Sales date is required."),
    }),
    async onSubmit(values) {
      try {
        const locationAddress = serializeAddress({
          addressLineOne: values.addressLineOne,
          addressLineTwo: values.addressLineTwo,
          city: values.addressCity,
          state: values.addressState,
          zipCode: values.addressZipCode,
          country: values.addressCountry,
        });

        const companyAddress = serializeAddress({
          addressLineOne: values.companyAddressLineOne,
          addressLineTwo: values.companyAddressLineTwo,
          city: values.companyAddressCity,
          state: values.companyAddressState,
          zipCode: values.companyAddressZipCode,
          country: values.companyAddressCountry,
        });

        // Backend currently unnecessarily requires an id field for new companies and
        // name and address for existing companies. This obviously doesn't make sense,
        // and we have a ticket for fixing, but for now we have to supply it here.
        const company = values.createNewCompany
          ? {
              id: 0,
              name: values.companyName,
              address: values.useLocationAddress
                ? locationAddress
                : companyAddress,
            }
          : {
              id: values.company?.value!,
              name: "***",
              address: locationAddress,
            };

        const body: Schema["LocationCreate"] = {
          name: values.name,
          customerNumber: values.customerNumber,
          locationStatus: "PENDING_INSTALLATION",
          marketSegment: values.marketSegment,
          salesOrderId: values.salesOrderId,
          salesRepId: values.salesRep?.value!,
          dealValue: values.dealValue,
          projectManagerId: values.projectManager?.value!,
          installerId: values.installer?.value!,
          isSelfInstall: values.isSelfInstall,
          address: locationAddress,
          grandOpeningDate: values.grandOpeningDate,
          salesDate: values.salesDate,
          installDate: values.installDate,
          softOpeningDate: values.softOpeningDate,
          hiltonInnCode: values.hiltonInnCode,
          company: company,
        };

        const newLocation = await unpackResponse(
          client.POST("/locations", { body }),
        );
        navigate(`/location/${newLocation.id}/profile`);
      } catch (error) {
        const errorString = caughtValueToString(error);

        formik.setStatus(
          errorString.includes("uq_locations_customer_number")
            ? "Cannot create location with duplicate customer number."
            : errorString,
        );
      }
    },
  });

  const showAddressState = countryRequiresState(formik.values.addressCountry);
  const showCompanyAddressState = countryRequiresState(
    formik.values.companyAddressCountry,
  );

  const locationInfo = (
    <FormSection heading="Location Info">
      <InputsStack>
        <TextField
          {...getTextFieldProps(formik, "name")}
          label="Location name"
          variant="standard"
          fullWidth
        />
        <TextField
          {...getTextFieldProps(formik, "customerNumber")}
          label="Customer number"
          variant="standard"
          helperText="Customer number has to be unique."
          fullWidth
        />
      </InputsStack>
    </FormSection>
  );

  const locationAddress = (
    <FormSection heading="Address">
      <InputsStack>
        <TextField
          {...getTextFieldProps(formik, "addressLineOne")}
          label="Address line 1"
          variant="standard"
          fullWidth
        />
        <TextField
          {...getTextFieldProps(formik, "addressLineTwo")}
          label="Address line 2"
          variant="standard"
          fullWidth
        />
        <Box sx={{ display: "grid", gridTemplateColumns: "2fr 1fr", gap: 10 }}>
          <TextField
            {...getTextFieldProps(formik, "addressCity")}
            label="City"
            variant="standard"
            fullWidth
          />
          <TextField
            {...getTextFieldProps(formik, "addressZipCode")}
            label="ZIP Code"
            variant="standard"
            fullWidth
          />
        </Box>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: showAddressState ? "2fr 1fr" : "1fr",
            gap: 10,
          }}
        >
          <AutocompleteSelectField
            {...getAutocompleteSelectFieldProps(formik, "addressCountry")}
            label="Country"
            options={countryFormatter.enums}
            getOptionLabel={(option) => countryFormatter.format(option)}
          />

          {showAddressState && (
            <AutocompleteSelectField
              {...getAutocompleteSelectFieldProps(formik, "addressState")}
              options={getStatesOrProvincesByCountry(
                formik.values.addressCountry,
              )}
              getOptionLabel={(option) => stateFormatter.format(option)}
              label="State"
            />
          )}
        </Box>
      </InputsStack>
    </FormSection>
  );

  const createNewCompany = formik.values.createNewCompany;
  const billingCompany = (
    <FormSection heading="Billing Company">
      <InputsStack>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 8,
          }}
        >
          <CheckboxField
            {...getCheckboxFieldProps(formik, "createNewCompany")}
            label="Create new company"
          />

          {createNewCompany ? (
            <TextField
              {...getTextFieldProps(formik, "companyName")}
              label="Company name"
              variant="standard"
              fullWidth
              sx={{ gridColumn: "span 2" }}
            />
          ) : (
            <AsyncAutocompleteSelectField
              {...getAutocompleteSelectFieldProps(formik, "company")}
              label="Company"
              placeholder="Select existing company"
              loadingText="Select existing company (Loading...)"
              options={props.companies?.map((company) => {
                return { value: company.id, label: company.name };
              })}
              sx={{ gridColumn: "span 2" }}
            />
          )}
        </Box>

        {createNewCompany && (
          <>
            <CheckboxField
              {...getCheckboxFieldProps(formik, "useLocationAddress")}
              label="Use location address"
            />

            {!formik.values.useLocationAddress && (
              <>
                <TextField
                  {...getTextFieldProps(formik, "companyAddressLineOne")}
                  label="Address line 1"
                  variant="standard"
                  fullWidth
                />
                <TextField
                  {...getTextFieldProps(formik, "companyAddressLineTwo")}
                  label="Address line 2"
                  variant="standard"
                  fullWidth
                />
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "2fr 1fr",
                    gap: 10,
                  }}
                >
                  <TextField
                    {...getTextFieldProps(formik, "companyAddressCity")}
                    label="City"
                    variant="standard"
                    fullWidth
                  />
                  <TextField
                    {...getTextFieldProps(formik, "companyAddressZipCode")}
                    label="ZIP Code"
                    variant="standard"
                    fullWidth
                  />
                </Box>

                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: showCompanyAddressState
                      ? "2fr 1fr"
                      : "1fr",
                    gap: 10,
                  }}
                >
                  <AutocompleteSelectField
                    {...getAutocompleteSelectFieldProps(
                      formik,
                      "companyAddressCountry",
                    )}
                    label="Country"
                    options={countryFormatter.enums}
                    getOptionLabel={(option) => countryFormatter.format(option)}
                  />

                  {showCompanyAddressState && (
                    <AutocompleteSelectField
                      {...getAutocompleteSelectFieldProps(
                        formik,
                        "companyAddressState",
                      )}
                      options={getStatesOrProvincesByCountry(
                        formik.values.addressCountry,
                      )}
                      getOptionLabel={(option) => stateFormatter.format(option)}
                      label="State"
                    />
                  )}
                </Box>
              </>
            )}
          </>
        )}
      </InputsStack>
    </FormSection>
  );

  const deal = (
    <FormSection heading="Deal">
      <InputsStack>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 8,
          }}
        >
          <AsyncAutocompleteSelectField
            {...getAutocompleteSelectFieldProps(formik, "salesRep")}
            label="Sales Rep"
            placeholder="Select sales rep"
            loadingText="Select sales rep (Loading...)"
            options={props.salesReps?.map((contact) =>
              contactToOption(contact),
            )}
          />
          <DateField
            {...getDateFieldProps(formik, "salesDate")}
            label="Sales Date"
          />

          <TextField
            {...getTextFieldProps(formik, "salesOrderId")}
            label="Sales Order ID"
            variant="standard"
            fullWidth
          />
        </Box>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 8,
          }}
        >
          <NumberField
            {...getNumberFieldProps(formik, "dealValue")}
            value={formik.values.dealValue}
            label="Deal value"
            adornment="$"
          />

          <TextField
            {...getTextFieldProps(formik, "marketSegment")}
            variant="standard"
            fullWidth
            label="Market Segment"
            select
          >
            {marketSegment.enums.map((e) => {
              return (
                <MenuItem key={e} value={e}>
                  {marketSegment.format(e)}
                </MenuItem>
              );
            })}
          </TextField>

          <TextField
            {...getTextFieldProps(formik, "hiltonInnCode")}
            variant="standard"
            fullWidth
            label="Hilton Inn Code"
          />
        </Box>
      </InputsStack>
    </FormSection>
  );

  const isSelfInstall = formik.values.isSelfInstall;
  const installation = (
    <FormSection heading="Installation & Opening">
      <InputsStack>
        <AsyncAutocompleteSelectField
          {...getAutocompleteSelectFieldProps(formik, "projectManager")}
          label="Project Manager"
          placeholder="Select project manager"
          loadingText="Select project manager (Loading...)"
          options={props.projectManagers?.map((contact) =>
            contactToOption(contact),
          )}
        />

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 10,
          }}
        >
          <CheckboxField
            {...getCheckboxFieldProps(formik, "isSelfInstall")}
            label="Self-installation"
          />

          <AsyncAutocompleteSelectField
            {...getAutocompleteSelectFieldProps(formik, "installer")}
            label="Installer"
            placeholder="Select installer"
            loadingText="Select installer (Loading...)"
            options={props.installers?.map((contact) =>
              contactToOption(contact),
            )}
            disabled={isSelfInstall}
            sx={{ gridColumn: "span 2" }}
          />
        </Box>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 10,
          }}
        >
          <DateField
            {...getDateFieldProps(formik, "installDate")}
            label="Installation Date"
          />

          <DateField
            {...getDateFieldProps(formik, "softOpeningDate")}
            label="Soft Opening Date"
          />

          <DateField
            {...getDateFieldProps(formik, "grandOpeningDate")}
            label="Grand Opening Date"
          />
        </Box>
      </InputsStack>
    </FormSection>
  );

  return (
    <>
      <Paper sx={{ padding: 10 }}>
        <form onSubmit={formik.handleSubmit}>
          <Stack gap={12}>
            {locationInfo}
            {locationAddress}
            {billingCompany}
            {deal}
            {installation}
            {formik.status && <Alert severity="error">{formik.status}</Alert>}
          </Stack>

          <Stack
            direction="row"
            gap={4}
            sx={{ marginTop: 16 }}
            alignItems="center"
          >
            <Button
              variant="contained"
              type="submit"
              size="large"
              disabled={formik.isSubmitting}
            >
              Create location
            </Button>

            {formik.isSubmitting && (
              <CircularProgress size="1.5em" sx={{ marginRight: 2 }} />
            )}
          </Stack>
        </form>
      </Paper>
    </>
  );
}

export function CreateLocationViewFetch() {
  // We need the below data to render
  const { installers, projectManagers, salesReps } = useInternalStaffLists();
  const { data: companiesPage } = useClientSWR("/companies", {
    params: { query: { page_size: 1000 } },
  });

  const breadcrumb = [
    { label: "Locations", url: `/location/list` },
    { label: "Add Location" },
  ];

  return (
    <>
      <ViewHeader title="Create location" breadcrumb={breadcrumb} />

      <LocationForm
        installers={installers}
        projectManagers={projectManagers}
        salesReps={salesReps}
        companies={companiesPage?.content}
      />
    </>
  );
}
