import { MenuItem, Stack, TextField } from "@mui/material";
import { useFormik } from "formik";
import { unpackResponse, useClient } from "../../client";
import { Schema } from "../../client/types";
import { FormDialog } from "../../ui/form-dialog";
import { DialogState } from "../../ui/more-menu";
import { getTextFieldProps } from "../../ui/text-field";
import { caughtValueToString } from "../../utils/caught-error";

type FormValues = {
  hwPlatformId: number;
};

type SubmitValues = {
  hwPlatform: { id: number };
};

type Props = DialogState & {
  locationId: number;
  availableHardwareModels: Schema["DeviceModel"][];
  revalidateView: () => void;
};

function GatewayDeviceFormDialog(
  props: Props & {
    onSubmit: (values: SubmitValues) => Promise<void>;
    device?: Schema["Device"];
    title: string;
    submitLabel: string;
  },
) {
  const { device, availableHardwareModels } = props;

  const formik = useFormik<FormValues>({
    initialValues: {
      hwPlatformId: device?.hwPlatform.id ?? availableHardwareModels.at(0)?.id!,
    },
    async onSubmit(values) {
      try {
        await props.onSubmit({ hwPlatform: { id: values.hwPlatformId } });
        props.revalidateView();
        props.onClose();
      } catch (error) {
        formik.setStatus(caughtValueToString(error));
      }
    },
  });

  return (
    <FormDialog {...props} form={formik}>
      <Stack direction="column">
        <TextField
          {...getTextFieldProps(formik, "hwPlatformId")}
          variant="standard"
          fullWidth
          label="Hardware Platform"
          placeholder="Select hardware platform"
          select
        >
          {props.availableHardwareModels.map((deviceModel) => {
            return (
              <MenuItem value={deviceModel.id!}>
                {deviceModel.modelName}
              </MenuItem>
            );
          })}
        </TextField>
      </Stack>
    </FormDialog>
  );
}

export function CreateGatewayDeviceDialog(props: Props) {
  const client = useClient();

  return (
    <GatewayDeviceFormDialog
      {...props}
      title="Create Gateway Device"
      submitLabel="Create"
      onSubmit={async (values) => {
        await unpackResponse(
          client.POST("/devices", {
            body: {
              ...values,
              deviceCategory: "GATEWAY",
              status: "LEGACY",
              locationId: props.locationId,
            },
          }),
        );
      }}
    />
  );
}

export function UpdateGatewayDeviceDialog(
  props: Props & { device: Schema["Device"] },
) {
  const client = useClient();

  return (
    <GatewayDeviceFormDialog
      {...props}
      device={props.device}
      title="Update Gateway Device"
      submitLabel="Save"
      onSubmit={async (values) => {
        await unpackResponse(
          client.PUT("/devices/{deviceId}", {
            params: { path: { deviceId: props.device.id! } },
            body: { ...props.device, ...values },
          }),
        );
      }}
    />
  );
}
