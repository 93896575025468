import { Button } from "@mui/material";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { SetupIntent, Stripe } from "@stripe/stripe-js";
import { useEffect } from "react";
import { Link as ReactRouterLink, useParams } from "react-router-dom";
import { useClientSWR } from "../../client";
import { Schema } from "../../client/types";
import { stripe } from "../../stripe";
import { caughtValueToString } from "../../utils/caught-error";
import { EnumFormatter } from "../../utils/enum-formatter";
import { mf } from "../../utils/formatters";
import { ErrorCard, LocationFetchErrorCard } from "../info-card";
import LoadingSpinner from "../loading-spinner";
import { useAsyncState } from "../use-async-state";
import { ViewHeader } from "../view-header";

const content = {
  title: mf(`{companyName} Payment Method Status`),
  breadcrumbLabel: `Payment Method Status`,

  // https://stripe.com/docs/api/setup_intents/object#setup_intent_object-status
  setupIntentStatus: new EnumFormatter<SetupIntent.Status>({
    succeeded: `Success! Your payment method has been saved.`,
    processing: `Processing payment details. Please check back later.`,
    canceled: `Payment method setup has been canceled.`,
    requires_action: `Failed to process payment details (requires action).`,
    requires_confirmation: `Failed to process payment details (requires confirmation).`,
    requires_payment_method: `Failed to process payment details. Please try another payment method.`,
  }),
  setupIntentMissingParams: `Setup intent id not provided in query params.`,
};

async function getSetupIntent(stripe: Stripe, clientSecret: string) {
  const { setupIntent, error } = await stripe.retrieveSetupIntent(clientSecret);

  if (error) {
    console.error("Stripe returned error");
    console.error(error);

    // According to the spec, error.message includes a human-readable error
    // message.
    throw new Error(error.message);
  }

  // According to the Stripe docs, if error is undefined, setupIntent must
  // be present.
  return setupIntent!;
}

function StatusCheck(props: { company: Schema["Company"] }) {
  const stripe = useStripe();
  const {
    data: setupIntent,
    error,
    setStateFromPromise,
    setError,
  } = useAsyncState<SetupIntent>();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the "setup_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const searchParams = new URLSearchParams(window.location.search);
    const clientSecret = searchParams.get("setup_intent_client_secret");

    if (!clientSecret) {
      setError(content.setupIntentMissingParams);
      return;
    }

    setStateFromPromise(getSetupIntent(stripe, clientSecret));
  }, [setError, setStateFromPromise, stripe]);

  if (setupIntent) {
    return (
      <ErrorCard
        heading={content.setupIntentStatus.format(setupIntent?.status)}
        button={
          <Button
            variant="outlined"
            component={ReactRouterLink}
            to={`/company/${props.company.id}/payment-methods`}
          >
            Back to payment methods
          </Button>
        }
      />
    );
  }

  if (error) {
    const message = caughtValueToString(error);
    return (
      <ErrorCard
        heading="Fetch failed"
        message={`Data could not be retrieved from the server. ${message}`}
      />
    );
  }

  return <LoadingSpinner />;
}

function CompanySetupIntentView(props: { company: Schema["Company"] }) {
  return (
    <Elements stripe={stripe}>
      <ViewHeader
        title={content.title.format({ companyName: props.company.name })}
        breadcrumb={[
          {
            label: "Companies",
            url: `/company/list`,
          },
          {
            label: props.company.name,
            url: `/company/${props.company.id}/profile`,
          },
          {
            label: content.breadcrumbLabel,
          },
        ]}
      />

      <StatusCheck {...props} />
    </Elements>
  );
}

export function CompanySetupIntentViewFetch() {
  const companyId = Number(useParams<"companyId">().companyId);
  const { data: company, error } = useClientSWR("/companies/{companyId}", {
    params: { path: { companyId } },
  });

  if (error) {
    return <LocationFetchErrorCard error={error} />;
  }

  if (company) {
    return <CompanySetupIntentView company={company} />;
  }

  return <LoadingSpinner />;
}
