import { Schema } from "../client/types";
import { countryFormatter } from "../constants/countries";
import { stateEnumToLabel } from "../constants/statesAndProvinces";

function AddressLine(props: { children?: string | null }) {
  if (!props.children) {
    return null;
  }

  return (
    <>
      {props.children} <br />
    </>
  );
}

/**
 * Consistently formats addresses from PMB Schema.
 *
 * Wrap in the `<Typography>` component to apply formatting.
 */
export function Address(props: Schema["Address"]) {
  return (
    <>
      <AddressLine>{props.addressLineOne}</AddressLine>
      <AddressLine>{props.addressLineTwo}</AddressLine>
      <AddressLine>{`${props.city} ${props.zipCode}`}</AddressLine>
      <AddressLine>{stateEnumToLabel(props.state)}</AddressLine>
      <AddressLine>{countryFormatter.format(props.country)}</AddressLine>
    </>
  );
}
