import { InputAdornment, TextField } from "@mui/material";
import { FormikValues, useFormik } from "formik";

/**
 * Retrieves props from formik state that can be used by `<NumberField/>`.
 */
export function getNumberFieldProps<
  T extends FormikValues,
  Name extends string & keyof T,
>(formik: ReturnType<typeof useFormik<T>>, name: Name) {
  const value = formik.values[name];
  const wasTouched = formik.touched[name];
  const validationHint = formik.errors[name];

  return {
    name,
    value,
    onChange: (value: number | null) => {
      return formik.setFieldValue(name, value);
    },
    onBlur: () => formik.setFieldTouched(name),
    error: !!(wasTouched && validationHint),
    helperText: wasTouched && validationHint,
  };
}
/**
 * A field representing a number.
 *
 * The underlying text field will automatically be set to type
 * number, and optionally you can supply an adornment to clarify
 * the unit.
 *
 * If you are using this field with `formik`, you can use the
 * `getNumberFieldProps` helper to retrieve exactly the right props
 * from formik state.
 */
export function NumberField(props: {
  label: string;
  value: number | null;
  onChange: (value: number | null) => void;
  onBlur?: () => void;
  adornment?: string;
  helperText?: string;
  error: boolean;
  fullWidth?: boolean;
}) {
  return (
    <TextField
      {...props}
      value={props.value ?? ""}
      onChange={(event) => {
        const value = event.target.value;
        const number = value ? parseFloat(value) : null;
        props.onChange(number);
      }}
      variant="standard"
      type="number"
      InputProps={{
        startAdornment: props.adornment && (
          <InputAdornment position="start">{props.adornment}</InputAdornment>
        ),
      }}
    />
  );
}
