import { Grid, Typography } from "@mui/material";
import { Edit3 } from "react-feather";
import { useParams } from "react-router-dom";
import { useClientSWR } from "../../client";
import { Schema } from "../../client/types";
import { useCompanyPaymentMethods } from "../../hooks/usePaymentMethods";
import { SetUpPaymentMethodFetch } from "../../ui/add-payment-method-dialog";
import { Address } from "../../ui/address";
import { CompanyBillingToast } from "../../ui/billing-toast";
import { CompanyContactListCard } from "../../ui/company-contact-list-card";
import { CompanySubscriptionDetailsCardFetch } from "../../ui/company-subscription-details-card";
import { DialogIconButton } from "../../ui/dialog-button";
import { EditCompanyAddressDialog } from "../../ui/edit-company-address-dialog";
import { EditCompanyDialog } from "../../ui/edit-company-dialog";
import { CompanyFetchErrorCard } from "../../ui/info-card";
import LoadingSpinner from "../../ui/loading-spinner";
import { NameCard } from "../../ui/name-card";
import { RoleGuard } from "../../ui/role-guard";
import { useUserInfo } from "../../ui/user-info-context";
import { ViewCard } from "../../ui/view-card";
import { ViewHeader } from "../../ui/view-header";
import { isNonEmpty } from "../../utils/common";
import { getLocalStorage, localStorageKeys } from "../../utils/local-storage";

export function CompanyProfileFetch() {
  const { roles } = useUserInfo();
  const { companyId } = useParams<"companyId">();
  const {
    data: paymentMethodData,
    error: paymentMethodError,
    mutate: paymentMethodMutate,
  } = useCompanyPaymentMethods({
    companyId: Number(companyId),
  });
  const { data, error, mutate } = useClientSWR("/companies/{companyId}", {
    params: { path: { companyId: Number(companyId) } },
  });

  const paymentMethodSetupDialogShown = getLocalStorage(
    localStorageKeys.get("PAYMENT_SETUP_DIALOG_TRIGGERED"),
  );

  if (error) {
    return <CompanyFetchErrorCard error={error} />;
  }

  if (paymentMethodError) {
    return <CompanyFetchErrorCard error={paymentMethodError} />;
  }

  if (data && paymentMethodData) {
    return (
      <>
        {roles.includes("COMPANY_ADMIN") &&
        !paymentMethodSetupDialogShown &&
        !isNonEmpty(paymentMethodData.paymentMethods) &&
        !isNonEmpty(paymentMethodData.setupIntentVerificationRequests) ? (
          <>
            <SetUpPaymentMethodFetch
              companyId={Number(companyId)}
              stripeReturnUrl={`${window.location.origin}/company/${companyId}`}
              onSuccess={() => paymentMethodMutate()}
            />
          </>
        ) : null}
        <CompanyProfile
          {...data}
          revalidateView={() => {
            mutate();
            paymentMethodMutate();
          }}
        />
      </>
    );
  }

  return <LoadingSpinner />;
}

function CompanyProfile(
  props: Schema["Company"] & { revalidateView: () => void },
) {
  return (
    <>
      <ViewHeader
        title={props.name}
        breadcrumb={[
          {
            label: "Companies",
            url: `/company/list`,
          },
          {
            label: props.name,
          },
        ]}
      />

      <CompanyBillingToast companyId={props.id} />

      <Grid container spacing={6}>
        <Grid container item spacing={6} xs={12} lg={3}>
          <Grid item xs={12}>
            <NameCard
              name={props.name}
              action={
                <RoleGuard allowedRoles={["PMB_ADMIN", "COMPANY_ADMIN"]}>
                  <DialogIconButton
                    label="Edit company"
                    renderDialog={(dialogProps) => (
                      <EditCompanyDialog
                        {...dialogProps}
                        onSuccess={props.revalidateView}
                        company={props}
                      />
                    )}
                  >
                    <Edit3 />
                  </DialogIconButton>
                </RoleGuard>
              }
            />
          </Grid>

          <Grid item xs={12}>
            <ViewCard
              title="Company Address"
              headerAction={
                <RoleGuard allowedRoles={["PMB_ADMIN", "COMPANY_ADMIN"]}>
                  <DialogIconButton
                    label="Edit company address"
                    renderDialog={(dialogProps) => (
                      <EditCompanyAddressDialog
                        {...dialogProps}
                        company={props}
                        onSuccess={props.revalidateView}
                      />
                    )}
                  >
                    <Edit3 />
                  </DialogIconButton>
                </RoleGuard>
              }
              content={
                <Typography variant="body2">
                  <Address {...props.address} />
                </Typography>
              }
            />
          </Grid>

          <Grid item xs={12}>
            <CompanySubscriptionDetailsCardFetch companyId={props.id} />
          </Grid>
        </Grid>

        <Grid item xs={12} lg={9}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} mb={3}>
              <CompanyContactListCard {...props} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default CompanyProfile;
