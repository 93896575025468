import { Link } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@mui/material";
import { ReactNode } from "react";
import { RoleGuard } from "./role-guard";
import { ViewCard } from "./view-card";

export function LocationDevicesCard(props: {
  locationId: number;
  summaries?: {
    label: string;
    status: string;
    icon: ReactNode;
  }[];
}) {
  const { locationId, summaries } = props;
  const isLoading = !summaries;

  return (
    <ViewCard
      title="Location devices"
      content={
        isLoading ? (
          <Grid container item xs={12} spacing={2}>
            <Grid item paddingBottom={4}>
              <Typography>Loading devices...</Typography>
            </Grid>
          </Grid>
        ) : (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
            }}
          >
            {summaries.length === 0 ? (
              <Typography variant="body2">No devices added yet.</Typography>
            ) : (
              summaries.map((item) => {
                return (
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "auto minmax(0, 1fr)",
                      gap: 4,
                    }}
                  >
                    <Box
                      sx={{
                        aspectRatio: 1,
                        padding: 4,
                        backgroundColor: "background.default",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 1,
                      }}
                    >
                      {item.icon}
                    </Box>
                    <Box sx={{ alignSelf: "center" }}>
                      <Typography variant="body1" fontWeight={600}>
                        {item.label}
                      </Typography>
                      <Typography variant="body2">{item.status}</Typography>
                    </Box>
                  </Box>
                );
              })
            )}
          </Box>
        )
      }
      actions={
        <RoleGuard allowedRoles={["PMB_ADMIN", "PMB_VIEWER"]}>
          <Button
            component={Link}
            to={`/location/${locationId}/topology`}
            variant={"outlined"}
            color="primary"
            style={{ paddingTop: "8px" }}
          >
            View topology
          </Button>
        </RoleGuard>
      }
    />
  );
}
