import { unpackResponse, useClient } from "../client";
import { Schema } from "../client/types";
import { waitForStripeWebhooks } from "../utils/prototyping";
import { ConfirmDialog } from "./confirm-dialog";
import { getPaymentMethodName } from "./views/stripe-brand-formatter";

type SettlePaymentDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  locationId: number;
  paymentId: number;
  paymentMethod: Schema["PaymentMethod"];
};

export function PayFeeDialog(props: SettlePaymentDialogProps) {
  const paymentMethodName = getPaymentMethodName(
    props.paymentMethod.stripePaymentMethod,
  );

  return (
    <SettlePaymentDialog
      {...props}
      title="Pay reinstatement fee?"
      description={`Your default payment method (${paymentMethodName}) will be used to retry the selected payment.`}
      submitLabel="Pay"
    />
  );
}

export function RetryPaymentDialog(props: SettlePaymentDialogProps) {
  const paymentMethodName = getPaymentMethodName(
    props.paymentMethod.stripePaymentMethod,
  );
  return (
    <SettlePaymentDialog
      {...props}
      title="Retry payment?"
      description={`Your default payment method (${paymentMethodName}) will be used to retry the selected payment.`}
      submitLabel="Retry payment"
    />
  );
}

function SettlePaymentDialog(
  props: SettlePaymentDialogProps & {
    title: string;
    submitLabel: string;
    description: string;
  },
) {
  const client = useClient();
  const { onSuccess, ...dialogProps } = props;

  return (
    <ConfirmDialog
      {...dialogProps}
      onSubmit={async () => {
        await Promise.all([
          waitForStripeWebhooks(),
          unpackResponse(
            client.PATCH(
              "/locations/{locationId}/payments/{paymentId}/settle",
              {
                params: {
                  path: {
                    locationId: props.locationId,
                    paymentId: props.paymentId,
                  },
                },
              },
            ),
          ),
        ]);

        onSuccess();
      }}
    />
  );
}
