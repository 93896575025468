import { Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import { object, string } from "yup";
import { unpackResponse, useClient } from "../client";
import { Schema } from "../client/types";
import { caughtValueToString } from "../utils/caught-error";
import { DateField, getDateFieldProps } from "./date-field";
import { FormDialog } from "./form-dialog";
import { getTextFieldProps } from "./text-field";

export function ConfirmLocationDeactivationDialog(props: {
  isOpen: boolean;
  onClose: () => void;
  location: Schema["LocationBase"];
  revalidateView: () => void;
}) {
  const { location, ...dialogProps } = props;
  const client = useClient();

  const formik = useFormik({
    initialValues: {
      closeDate: "",
      confirmationString: "",
    },
    async onSubmit(values) {
      try {
        await unpackResponse(
          client.PATCH("/locations/{locationId}/deactivate", {
            params: {
              path: { locationId: location.id },
              query: { close_date: values.closeDate },
            },
          }),
        );
        props.revalidateView();
        props.onClose();
      } catch (error) {
        formik.setStatus(caughtValueToString(error));
      }
    },
    validationSchema: object().shape({
      closeDate: string().required("Close date is required."),
      confirmationString: string()
        .required("Confirmation text is required.")
        .oneOf(
          [location.name],
          "Confirmation text must match location name exactly.",
        ),
    }),
  });

  return (
    <FormDialog
      {...dialogProps}
      form={formik}
      title={`Deactivate ${location.name}?`}
      submitLabel="Deactivate"
      submitColor="error"
    >
      <Typography>
        <strong>BEWARE!</strong> Location deactivation has significant impact
        and <strong>cannot be undone</strong>! Once deactivated, the following
        events will be triggered:
      </Typography>
      <Box component="ul" sx={{ marginTop: 4, marginBottom: 3 }}>
        <Typography component="li">
          Location will be marked as permanently deactivated.
        </Typography>
        <Typography component="li">
          Its parent company will also be deactivated (unless there is another
          active location bound to it).
        </Typography>
        <Typography component="li">
          All location and company contacts will be deleted and users will lose
          access to PMB cloud application.
        </Typography>
        <Typography component="li">
          All location devices will be disabled and disconnected from the
          network.
        </Typography>
      </Box>

      <Typography>
        Historic transactional and billing data will remain accessible in the
        app for PMB admin users.
      </Typography>
      <Typography sx={{ marginTop: 4 }}>
        Please write the name of this location ({location.name}) into the field
        below to confirm.
      </Typography>

      <Stack gap={4} sx={{ marginTop: 8 }}>
        <TextField
          {...getTextFieldProps(formik, "confirmationString")}
          label="Confirmation"
          variant="standard"
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
        <DateField
          {...getDateFieldProps(formik, "closeDate")}
          label="Close date"
        />
      </Stack>
    </FormDialog>
  );
}
