import { Schema } from "../client/types";
import { EnumFormatter } from "../utils/enum-formatter";

export const marketSegment = new EnumFormatter<Schema["MarketSegment"]>({
  RESTAURANT_BAR_TAPROOM: "Restaurant/Bar/Taproom",
  HOTEL_CASINO: "Hotel/Casino",
  GOLF_COUNTRY_CLUB: "Golf/Country Club",
  BUILDING_COMMUNITY: "Building/Community",
  VENUE_PARK: "Venue/Park",
  MILITARY: "Military",
  GROCERY: "Grocery",
  INTERNATIONAL: "International",
  PICKLEBALL: "Pickleball",
  ENTERTAINMENT_BARCADE: "Entertainment/Barcade",
});
