import { useCallback, useState } from "react";

export type Pageable = ReturnType<typeof usePageable>;

export type GetSortLabelProps = Pageable["getSortLabelProps"];

function strinfigySortQuery(sort: { prop: string; direction: "asc" | "desc" }) {
  return `${sort.prop}(${sort.direction})`;
}

function getElementsPerPageOptions(elementsPerPageOptions?: number[]) {
  if (!elementsPerPageOptions) {
    return {
      elementsPerPageOptions: [20, 50, 100],
      initialPageSize: 20,
    };
  }

  return {
    elementsPerPageOptions,
    initialPageSize: elementsPerPageOptions[0],
  };
}

export function usePageable(props: {
  initialSortProp: string;
  initialSortDirection?: "asc" | "desc";
  elementsPerPageOptions?: number[];
}) {
  // Page Number
  const [pageNumber, setPageNumber] = useState(0);

  // Page Size
  const { elementsPerPageOptions, initialPageSize } = getElementsPerPageOptions(
    props.elementsPerPageOptions,
  );
  const [pageSize, setPageSize] = useState(initialPageSize);
  const onSetPageSize = useCallback((size: number) => {
    // When changing page size, we reset page number to 0, as otherwise
    // users would end up seeing results from an arbitrary location which
    // would be quite confusing.
    setPageNumber(0);
    setPageSize(size);
  }, []);

  // Sort
  const [sortProp, setSortProp] = useState(props.initialSortProp);
  const [sortDirection, setSortDirection] = useState(
    props.initialSortDirection ?? "asc",
  );

  const getSortLabelProps = useCallback(
    (targetProp: string) => {
      const active = sortProp === targetProp;

      return {
        onClick: () => {
          setSortProp(targetProp);

          // When changing sort direction or prop, we always want to reset
          // page number to 0, to make sure we are not showing results
          // from the middle.
          setPageNumber(0);

          // If the currently selected prop was clicked, we need to switch
          // sort direction. Otherwise, always default to ascending when
          // new prop is being set.
          setSortDirection(
            targetProp === sortProp
              ? sortDirection === "asc"
                ? "desc"
                : "asc"
              : "asc",
          );
        },

        // Non-active labels should always show ascending direction as that
        // is what will be set once clicked.
        direction: active ? sortDirection : "asc",

        // Is this prop the currently selected prop?
        active,
      };
    },
    [sortDirection, sortProp],
  );

  return {
    setPageNumber,
    setPageSize,
    pageSize,

    /**
     * `getSortLabelProps` should be used to return sorting-related props
     * to a component which handles page sorting. Usually this would be
     * `<TableColumnHeadCell/>`.
     *
     * @example
     * ```tsx
     * <TableColumnHeadCell sort={getSortLabelProps("name")}>
     *   Name
     * </TableColumnHeadCell>
     * ```
     */
    getSortLabelProps,

    /**
     * `paginationProps` should be passed to a pagination component to
     * handle page pagination. Usually this would be `<ViewTablePagination/>`
     *
     * @example
     * ```tsx
     * <ViewTablePagination {...pageable.paginationProps} />
     * ```
     */
    paginationProps: {
      onSetPageNumber: setPageNumber,
      onSetPageSize: onSetPageSize,
      pageSize,
      pageNumber,
      elementsPerPageOptions,
    },

    /**
     * `query` should be passed to the query params of the appropriate
     * fetch function.
     */
    query: {
      page_size: pageSize,
      page_number: pageNumber,
      sort: strinfigySortQuery({ prop: sortProp, direction: sortDirection }),
    },
  };
}
