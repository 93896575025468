import { Box, Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useClientSWR } from "../../client";
import { Schema } from "../../client/types";
import { ErrorCard } from "../../ui/info-card";
import LoadingSpinner from "../../ui/loading-spinner";
import { ViewHeader } from "../../ui/view-header";
import { groupBy } from "../../utils/common";
import { ScreenDeviceCard } from "./device-cards";

function DeviceGroupView(props: {
  location: Schema["Location"];
  deviceGroup: Schema["DeviceGroup"];
  deviceModels: Schema["DeviceModel"][];
  revalidateView: () => void;
}) {
  const { location, deviceGroup, deviceModels } = props;
  const deviceGroupLabel = deviceGroup.label || "Unnamed Device Group";
  const deviceModelsByCategory = groupBy(deviceModels, (d) => d.modelCategory);

  const breadcrumb = [
    { label: "Locations", url: `/location/list` },
    { label: location.name, url: `/location/${location.id}/profile` },
    { label: "Topology", url: `/location/${location.id}/topology` },
    { label: deviceGroupLabel },
  ];

  const header = (
    <ViewHeader title={deviceGroupLabel} breadcrumb={breadcrumb} />
  );

  // Technically this should not happen, but we handle it here just to make sure
  // we cover this case as a fallback to incorrect BE behaviour.
  if (!deviceGroup.devices || deviceGroup.devices.length === 0) {
    return (
      <>
        {header}

        <Box>
          <Typography variant="body2">No devices added yet.</Typography>
        </Box>
      </>
    );
  }

  return (
    <>
      {header}

      <Stack gap={4}>
        <Typography variant="h5">Screens</Typography>

        {deviceGroup.devices.map((device) => {
          return (
            <ScreenDeviceCard
              key={device.id}
              device={device}
              revalidateView={props.revalidateView}
              locationId={location.id}
              deviceModelsByCategory={deviceModelsByCategory}
              allowDelete={false}
            />
          );
        })}
      </Stack>
    </>
  );
}

export function DeviceGroupFetch() {
  const params = useParams<"locationId" | "deviceGroupId">();
  const locationId = parseInt(params.locationId!, 10);
  const deviceGroupId = parseInt(params.deviceGroupId!, 10);

  const { data: location, error: locationError } = useClientSWR(
    "/locations/{locationId}",
    { params: { path: { locationId } } },
  );

  const {
    data: deviceGroup,
    error: deviceGroupError,
    mutate: revalidateDeviceGroup,
  } = useClientSWR("/device-groups/{deviceGroupId}", {
    params: { path: { deviceGroupId } },
  });

  const {
    data: deviceGroupDevices,
    error: deviceGroupDevicesError,
    mutate: revalidatedeviceGroupDevices,
  } = useClientSWR("/device-groups/{deviceGroupId}", {
    params: { path: { deviceGroupId } },
  });

  const { data: deviceModels, error: devicesModelsError } =
    useClientSWR("/devices/models");

  if (deviceGroup && deviceGroupDevices && location && deviceModels) {
    const revalidateView = () => {
      revalidateDeviceGroup();
      revalidatedeviceGroupDevices();
    };

    return (
      <DeviceGroupView
        location={location}
        deviceGroup={deviceGroup}
        deviceModels={deviceModels}
        revalidateView={revalidateView}
      />
    );
  }

  const error =
    deviceGroupError ||
    deviceGroupDevicesError ||
    locationError ||
    devicesModelsError;

  if (error) {
    return (
      <ErrorCard
        heading="Error while fetching device group detail"
        message={error.message}
      />
    );
  }

  return <LoadingSpinner />;
}
