import {
  PMBClient,
  UnknownOpenApiResponse,
  unpackResponse,
  useClient,
} from "../client";
import { Schema } from "../client/types";
import { ConfirmDialog } from "./confirm-dialog";

export function AssignPrimaryContact<T extends UnknownOpenApiResponse>(props: {
  isOpen: boolean;
  contact: Pick<Schema["Contact"], "id" | "fullName">;
  onClose: () => void;
  onSuccess: () => void;
  onSubmit: (client: PMBClient) => Promise<T>;
  subjectName: string;
}) {
  const client = useClient();
  const { contact, onSuccess, ...dialogProps } = props;

  return (
    <ConfirmDialog
      {...dialogProps}
      title={`Assign ${props.contact.fullName} as primary contact?`}
      description={`${props.contact.fullName} will be assigned as primary contact for ${props.subjectName}. This means they will be informed of important events, such as issues with billing.`}
      submitLabel="Assign"
      onSubmit={async () => {
        await unpackResponse(props.onSubmit(client));
        onSuccess();
      }}
    />
  );
}
