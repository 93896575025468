// Find the description here: https://rune.ableneo.com/display/PMB/Device%2C+Device+Model%2C+Software+Version

import { Schema } from "../../client/types";
import { EnumFormatter } from "../../utils/enum-formatter";

export enum DeviceStatusKey {
  LEGACY = "LEGACY",
  DRAFT = "DRAFT",
  PENDING_INSTALLATION = "PENDING_INSTALLATION",
  CONNECTED = "CONNECTED",
  DISABLED = "DISABLED",
}

type DeviceStatus = Schema["Device"]["status"];

export const deviceStatusV2 = new EnumFormatter<DeviceStatus>({
  LEGACY: "Legacy",
  DRAFT: "Draft",
  PENDING_INSTALLATION: "Pending Installation",
  CONNECTED: "Connected",
  DISABLED: "Disabled",
});
