import { useCallback } from "react";
import useSWR from "swr";
import { unpackResponse, useClient } from "../client";
import { Schema } from "../client/types";

/**
 * Custom SWR for getting custom payment structure.
 *
 * Due to the fact that custom payment structure is retrieved with POST, we cannot
 * use the usual useClientSWR hook.
 */
export const useCustomPaymentStructure = (props: {
  locationId: number;
  subscription: Schema["Subscription"];
}) => {
  const client = useClient();

  // The fetcher function has to be stabilized with useCallback to make
  // sure it doesn't change on every render.
  const fetcher = useCallback(async (): Promise<Schema["PaymentStructure"]> => {
    return await unpackResponse(
      client.POST("/locations/{locationId}/custom-payment-structure", {
        params: { path: { locationId: props.locationId } },
        body: {
          paymentFrequency: props.subscription.paymentFrequency,
          discountType: props.subscription.discountType,
          discountValidTo: props.subscription.discountValidTo,
          percentageDiscount: props.subscription.percentageDiscount,
          lumpsumDiscount: props.subscription.lumpsumDiscount,
          accountsManagement: props.subscription.accountsManagement,
        },
      }),
    );
  }, [client, props.locationId, props.subscription]);

  return useSWR("/locations/{locationId}/custom-payment-structure", fetcher);
};
