import styled from "@emotion/styled";
import { Autocomplete, TextField } from "@mui/material";
import { darken } from "polished";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Search as SearchIcon } from "react-feather";
import { useNavigate } from "react-router-dom";
import { useClient } from "../client";
import { Schema } from "../client/types";
import { includesAnyOf } from "../utils/one-of";
import { useUserInfo } from "./user-info-context";

const Search = styled.div`
  display: none;

  ${(props) => props.theme.breakpoints.up("md")} {
    display: flex;
    border-radius: 12px;
    background-color: ${(props) => props.theme.header.background};
    position: relative;
    width: 100%;

    &:hover {
      background-color: ${(props) =>
        darken(0.05, props.theme.header.background)};
    }
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;

function useUniqueOptions(items: Schema["LocationSearchListItem"][]) {
  return useMemo(() => {
    return items.map((item) => {
      return {
        label: `${item.name} (${item.customerNumber})`,
        id: item.id,
      };
    });
  }, [items]);
}

/**
 * LocationSearch is a stateless component that renders a search
 * with options of locations if provided, otherwise empty.
 */
function LocationSearch(props: {
  locationData: Array<Schema["LocationSearchListItem"]>;
}) {
  const navigate = useNavigate();
  const textFieldRef = useRef<HTMLInputElement>(null);
  const [locationOption, setLocationOption] = useState<{
    label: string;
    id: number;
  } | null>(null);

  const options = useUniqueOptions(props.locationData);

  const handleChange = (option: { label: string; id: number } | null) => {
    if (!option) {
      return;
    }

    navigate(`/location/${option?.id}/profile`);
    textFieldRef.current?.blur();
  };

  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>

      <Autocomplete
        sx={{
          color: "inherit",
          width: "100%",
          border: "none",
          paddingTop: 1,
          paddingRight: 2.5,
          paddingBottom: 1,
          paddingLeft: 12,

          "& > div > div::before": {
            display: "none",
          },

          "& > div > div::after": {
            display: "none",
          },

          "& > div > div > div": {
            display: "none",
          },
        }}
        value={locationOption}
        options={options}
        onChange={(_event, value) => {
          setLocationOption(null);
          handleChange(value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            name="search"
            variant="standard"
            placeholder="Go to location"
            inputRef={textFieldRef}
          />
        )}
      />
    </Search>
  );
}

/**
 * GlobalSearch is a stateful component retrieves location-search data from the
 * server, handles errors, and renders a global search bar once ready
 * for selected user roles (System Admin, PMB Admin and PMB Viewer).
 */
export function GlobalSearch() {
  const client = useClient();
  const { roles } = useUserInfo();
  const allowedRoles: Schema["Role"][] = [
    "SYSTEM_ADMIN",
    "PMB_ADMIN",
    "PMB_VIEWER",
  ];
  const showGlobalSearch = includesAnyOf(roles, allowedRoles);
  const [searchData, setSearchData] =
    useState<Schema["LocationSearchListItem"][]>();

  const fetchSearchData = useCallback(async () => {
    const { data, error } = await client.GET("/locations/search");

    if (error) {
      alert("Error fetching search data, Error: " + error.message);
    }

    if (data) {
      setSearchData(data);
    }
  }, [client]);

  useEffect(() => {
    if (showGlobalSearch) {
      fetchSearchData();
    }
  }, [fetchSearchData, showGlobalSearch]);

  if (searchData) {
    return <LocationSearch locationData={searchData} />;
  }

  return null;
}
