import { PMBContact } from "../client/types";

export const getKeyByValue = <T extends Record<string, string>>(
  object: T,
  searchValue: string,
  defaultKey?: any,
): keyof T => {
  const keys = Object.keys(object);
  defaultKey = defaultKey ?? keys.length > 0 ? keys[0] : "UNKNOWN";
  for (const key in object) {
    if (object[key] === searchValue) {
      return key;
    }
  }
  return defaultKey;
};

export function countBy<T, K extends string>(
  items: T[],
  getKey: (item: T) => K,
) {
  const groups: Partial<Record<K, number>> = {};

  for (const item of items) {
    const key = getKey(item);
    groups[key] ??= 0;
    groups[key]!++;
  }

  // Using a Proxy to make sure that even if one of the possible group keys
  // was not included in the provided list, that group will still return
  // a zero rather than `undefined`.
  return new Proxy(groups, {
    get(target, prop, receiver) {
      return Reflect.get(target, prop, receiver) ?? [];
    },
  }) as Record<K, number>;
}

export function groupBy<T, K extends string>(
  items: T[],
  getKey: (item: T) => K,
) {
  const groups: Partial<Record<K, T[]>> = {};

  for (const item of items) {
    const key = getKey(item);
    const group = groups[key];
    if (group) {
      group.push(item);
    } else {
      groups[key] = [item];
    }
  }

  // Using a Proxy to make sure that even if one of the possible group keys
  // was not included in the provided list, that group will still return
  // an empty list rather than `undefined`.
  return new Proxy(groups, {
    get(target, prop, receiver) {
      return Reflect.get(target, prop, receiver) ?? [];
    },
  }) as Record<K, T[]>;
}

export function groupByToEntries<T, K extends string>(
  items: T[],
  getKey: (item: T) => K,
) {
  return Object.entries(groupBy(items, getKey)) as Array<[K, T[]]>;
}

export const getContactLabel = (props: {
  contact: PMBContact;
  excludePosition?: boolean;
}) => {
  return (
    props.contact.fullName +
    " (" +
    props.contact.email +
    ") " +
    (props.excludePosition ? "" : props.contact.position)
  );
};

/**
 * Checks whether the supplied array is defined and non empty.
 */
export function isNonEmpty<T>(value?: T[]): value is T[] {
  return Array.isArray(value) && value.length > 0;
}

/**
 * Intersperses a provided item of type U between elements of an array of type T.
 */
export function intersperse<T, U>(items: T[], item: U) {
  const result: (T | U)[] = [];

  for (let i = 0; i < items.length; i++) {
    result.push(items[i]);
    if (i < items.length) {
      result.push(item);
    }
  }

  return result;
}

/**
 * Checks if every property of the given object is either undefined or null.
 *
 * @example
 * ```ts
 * const obj = { a: null, b: undefined, c: null };
 * console.log(isEveryPropNullish(obj)); // true
 *
 * const obj2 = { a: 1, b: null, c: undefined };
 * console.log(isEveryPropNullish(obj2)); // false
 * ```
 */
export function isEveryPropNullish(object: Object): boolean {
  return Object.values(object).every(
    (value) => value === undefined || value === null,
  );
}
