import { Schema } from "../../client/types";
import { EnumMapper } from "../../utils/enum-formatter";

// https://stripe.com/docs/api/payment_methods/object
export const brand = new EnumMapper<string, string>(
  {
    amex: "American Express",
    diners: "Diners Club",
    discover: "Discover",
    eftpos_au: "eftpos",
    jcb: "JCB",
    mastercard: "Mastercard",
    unionpay: "UnionPay",
    visa: "Visa",
  },
  "Card",
);

export const brandIcon = new EnumMapper<string, string>(
  {
    amex: "/static/cards/amex.png",
    diners: "/static/cards/diners.png",
    jcb: "/static/cards/jcb.png",
    mastercard: "/static/cards/mastercard.png",
    unionpay: "/static/cards/unionpay.png",
    visa: "/static/cards/visa.png",
  },
  "/static/cards/card.png",
);

type StripePaymentMethod = NonNullable<
  Schema["PaymentMethod"]["stripePaymentMethod"]
>;

export function getCardName(card: NonNullable<StripePaymentMethod["card"]>) {
  const brandName = brand.get(card.brand);
  return `${brandName} ending in ${card.last4}`;
}

export function getBankAccountName(
  account: NonNullable<StripePaymentMethod["us_bank_account"]>,
) {
  const bankName = account.bank_name ?? "Bank";
  return `${bankName} account ending in ${account.last_4}`;
}

export function getPaymentMethodName(stripePaymentMethod: StripePaymentMethod) {
  if (stripePaymentMethod.type === "card" && stripePaymentMethod.card) {
    return getCardName(stripePaymentMethod.card);
  }

  if (
    stripePaymentMethod.type === "us_bank_account" &&
    stripePaymentMethod.us_bank_account
  ) {
    return getBankAccountName(stripePaymentMethod.us_bank_account);
  }

  return "Unknown payment method";
}
