import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useClientSWR } from "../client";
import { operations } from "../client/pmb";
import { Schema } from "../client/types";
import { billingStatus } from "../constants/billingStatus";
import { locationStatus } from "../constants/locationStatus";
import { Nullable } from "../utils/types";
import {
  AsyncAutocompleteSelectField,
  getAutocompleteSelectFieldProps,
} from "./async-autocomplete-select-field";
import { ChipsGroup } from "./chips-group";
import { FormDialog } from "./form-dialog";

export type FormValues = Nullable<{
  subscriptionStatus: Schema["SubscriptionStatus"];
  locationStatus: Schema["LocationStatus"];
  billingStatus: Schema["BillingStatus"];
  company: { value: number; label: string };
  projectManager: { value: number; label: string };
  salesRep: { value: number; label: string };
}>;

function ToggelChip(props: {
  label: string;
  active: boolean;
  onClick: () => void;
}) {
  return (
    <Chip
      label={props.label}
      variant="filled"
      color={props.active ? "primary" : "default"}
      onClick={props.onClick}
    />
  );
}

export function valuesToQuery(
  values: FormValues,
): operations["getLocations"]["parameters"]["query"] {
  return {
    company_id: values.company?.value,
    sales_rep_id: values.salesRep?.value,
    project_manager_id: values.projectManager?.value,
    location_status: values.locationStatus ?? undefined,
    billing_status: values.billingStatus ?? undefined,
    subscription_status: values.subscriptionStatus ?? undefined,
    status: values.locationStatus === "CLOSED" ? "INACTIVE" : "ACTIVE",
  };
}

export function LocationFilterDialog(props: {
  isOpen: boolean;
  onClose: () => void;
  initialValues: FormValues;
  onSubmit: (values: FormValues) => void;
}) {
  const { data: companies } = useClientSWR("/companies/search");
  const { data: salesReps } = useClientSWR("/contacts/search/sales-reps");
  const { data: projectManagers } = useClientSWR(
    "/contacts/search/project-managers",
  );

  const formik = useFormik<FormValues>({
    initialValues: props.initialValues,
    onSubmit: (values) => {
      props.onSubmit(values);
      props.onClose();
    },
  });
  const { data } = useClientSWR("/locations", {
    params: { query: valuesToQuery(formik.values) },
  });

  return (
    <FormDialog
      {...props}
      title="Filter locations"
      submitLabel={
        data ? `Show ${data.totalElements} locations` : "Show locations"
      }
      form={formik}
    >
      <Box
        sx={{ paddingTop: 2, display: "flex", flexDirection: "column", gap: 6 }}
      >
        <Typography>
          Specify filtering criteria using the fields below.
        </Typography>

        <ChipsGroup label="Location Status">
          {locationStatus.enums.map((value) => {
            const isActive = formik.values.locationStatus === value;
            return (
              <ToggelChip
                active={isActive}
                label={locationStatus.format(value)}
                onClick={() => {
                  formik.setFieldValue(
                    "locationStatus",
                    isActive ? null : value,
                  );
                }}
              />
            );
          })}
        </ChipsGroup>

        <ChipsGroup label="Billing Status">
          {billingStatus.enums.map((value) => {
            const isActive = formik.values.billingStatus === value;

            return (
              <ToggelChip
                active={isActive}
                label={billingStatus.format(value)}
                onClick={() => {
                  formik.setFieldValue(
                    "billingStatus",
                    isActive ? null : value,
                  );
                }}
              />
            );
          })}
        </ChipsGroup>

        <AsyncAutocompleteSelectField
          {...getAutocompleteSelectFieldProps(formik, "salesRep")}
          options={salesReps?.map((contact) => {
            return { label: contact.fullName, value: contact.id };
          })}
          label="Sales Rep"
          placeholder="Filter by sales rep..."
          loadingText="Filter by sales rep... (Loading)"
        />
        <AsyncAutocompleteSelectField
          {...getAutocompleteSelectFieldProps(formik, "projectManager")}
          options={projectManagers?.map((contact) => {
            return { label: contact.fullName, value: contact.id };
          })}
          label="Project Manager"
          placeholder="Filter by project manager..."
          loadingText="Filter by project manager... (Loading)"
        />
        <AsyncAutocompleteSelectField
          {...getAutocompleteSelectFieldProps(formik, "company")}
          options={companies?.map((company) => {
            return {
              label: `${company.name}`,
              value: company.id,
            };
          })}
          label="Company"
          placeholder="Filter by company..."
          loadingText="Filter by company... (Loading)"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={formik.values.subscriptionStatus === "MANUAL"}
              onChange={() => {
                formik.setFieldValue(
                  "subscriptionStatus",
                  formik.values.subscriptionStatus === "MANUAL"
                    ? undefined
                    : "MANUAL",
                );
              }}
            />
          }
          label="Location is billed manually"
        />
      </Box>
    </FormDialog>
  );
}
