import { Schema } from "../client/types";
import { useUserInfo } from "../ui/user-info-context";
import { includesAnyOf } from "../utils/one-of";

type Permission =
  | {
      type: "CHANGE_SUBSCRIPTION_PAYMENT_FREQUENCY";
      subscription: Schema["Subscription"];
    }
  | {
      type: "VIEW_ANNUAL_PROMO";
      subscription: Schema["Subscription"];
    }
  | {
      type: "MANAGE_COMPANY_PAYMENT_METHODS";
    }
  | {
      type: "TOGGLE_LOCATION_BILLING_STATUS";
      subscription: Schema["Subscription"];
    };

export function useQueryPermissions<T extends Permission>(queries: T[]) {
  const { roles } = useUserInfo();
  const permissions = new Map<T["type"], boolean>();

  for (const query of queries) {
    switch (query.type) {
      case "CHANGE_SUBSCRIPTION_PAYMENT_FREQUENCY": {
        permissions.set(
          query.type,
          roles.includes("COMPANY_ADMIN") && !query.subscription.isSeasonal,
        );

        break;
      }

      case "VIEW_ANNUAL_PROMO": {
        permissions.set(
          query.type,
          roles.includes("COMPANY_ADMIN") &&
            query.subscription.paymentFrequency === "MONTHLY" &&
            !query.subscription.isSeasonal,
        );

        break;
      }

      case "MANAGE_COMPANY_PAYMENT_METHODS": {
        permissions.set(query.type, roles.includes("COMPANY_ADMIN"));
        break;
      }

      case "TOGGLE_LOCATION_BILLING_STATUS": {
        permissions.set(
          query.type,
          query.subscription.status === "MANUAL" &&
            includesAnyOf<Schema["Role"]>(roles, ["SYSTEM_ADMIN", "PMB_ADMIN"]),
        );
        break;
      }
    }
  }

  return {
    isAllowed(query: T["type"]) {
      return permissions.get(query) ?? false;
    },
  };
}
