import { Schema } from "../client/types";
import { EnumFormatter } from "../utils/enum-formatter";

export const subscriptionStatus = new EnumFormatter<
  Schema["SubscriptionStatus"]
>({
  ACTIVE: "Automated",
  MANUAL: "Manual",
  PENDING_ACTIVATION: "Pending activation",
  CLOSED: "Closed",
});
