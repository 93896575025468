import { Box, Paper, Typography } from "@mui/material";
import { ReactNode } from "react";
import { Schema } from "../client/types";

export function ErrorMessage(props: {
  heading: string;
  message?: string;
  button?: ReactNode;
}) {
  return (
    <>
      <Box
        sx={{
          aspectRatio: "3/1",
          display: "flex",
          alignItems: "center",
          padding: 8,
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ maxWidth: 360 }}>
          <Typography
            variant="h4"
            component="p"
            sx={{ color: "text.secondary", paddingBottom: 2 }}
          >
            {props.heading}
          </Typography>

          {props.message && (
            <Typography
              variant="caption"
              component="p"
              sx={{ color: "text.secondary" }}
            >
              {props.message}
            </Typography>
          )}

          {props.button && <Box sx={{ mt: 4 }}>{props.button}</Box>}
        </Box>
      </Box>
    </>
  );
}

export function ErrorCard(props: {
  heading: string;
  message?: string;
  button?: ReactNode;
}) {
  return (
    <Paper sx={{ alignSelf: "center" }}>
      <ErrorMessage {...props} />
    </Paper>
  );
}

// Before 2023-12-28, error response bodies did not include properly typed
// data. Eventually, we should remove this error type, but for now we have
// to keep it until it is fully refactored on the backend.
type LegacyError = Record<string, string>;

type FetchError = Schema["Error"] | LegacyError;

/**
 * `FetchErrorCard` is a general-purpose component that should be used to
 * handle fetch errors coming from the API.
 *
 * Generally, you should probably be using one of the pre-made components
 * like `<LocationFetchErrorCard />` to handle fetch errors in a particular
 * view context.
 */
export function FetchErrorCard(props: {
  error: FetchError;
  entitySingular: string;
}) {
  switch (props.error.code) {
    case "ENTITY_NOT_FOUND": {
      return (
        <ErrorCard
          heading={`${props.entitySingular} not found`}
          message={`The link you followed might be broken, or the ${props.entitySingular.toLowerCase()} that used to be here was removed.`}
        />
      );
    }

    case "ACCESS_DENIED":
      return (
        <ErrorCard
          heading="Access denied"
          message={`The link you followed might be broken, or you might not be logged in with the correct account. Try refreshing the page.`}
        />
      );

    case "UNAUTHORIZED": {
      return (
        <ErrorCard
          heading="Not authorized"
          message={`Your session might have expired. Try refreshing the page.`}
        />
      );
    }

    default: {
      return (
        <ErrorCard
          heading="Fetch failed"
          message={`Data could not be retrieved from the server. Check your internet connection and try again.`}
        />
      );
    }
  }
}

export function LocationFetchErrorCard(props: { error: FetchError }) {
  return <FetchErrorCard error={props.error} entitySingular="Location" />;
}

export function CompanyFetchErrorCard(props: { error: FetchError }) {
  return <FetchErrorCard error={props.error} entitySingular="Company" />;
}

export function ContactFetchErrorCard(props: { error: FetchError }) {
  return <FetchErrorCard error={props.error} entitySingular="Contact" />;
}

export function TransactionFetchErrorCard(props: { error: FetchError }) {
  return <FetchErrorCard error={props.error} entitySingular="Payment" />;
}
