import { Schema } from "../client/types";
import { EnumFormatter } from "../utils/enum-formatter";

export const locationStatus = new EnumFormatter<Schema["LocationStatus"]>({
  PENDING_INSTALLATION: "Pending Installation",
  PENDING_OPENING: "Pending Opening",
  ACTIVE: "Active",
  CLOSED: "Deactivated",
  LEGACY: "Legacy",
});
