import { MenuItem, Stack, TextField } from "@mui/material";
import { useFormik } from "formik";
import { unpackResponse, useClient } from "../../client";
import { Schema } from "../../client/types";
import { FormDialog } from "../../ui/form-dialog";
import { DialogState } from "../../ui/more-menu";
import { getTextFieldProps } from "../../ui/text-field";
import { caughtValueToString } from "../../utils/caught-error";

type FormValues = {
  label: string;
  hwPlatformId: number;
};

type SubmitValues = {
  label: string;
  hwPlatform: { id: number };
};

type Props = DialogState & {
  locationId: number;
  availableHardwareModels: Schema["DeviceModel"][];
  revalidateView: () => void;
};

function ChargerDeviceFormDialog(
  props: Props & {
    onSubmit: (values: SubmitValues) => Promise<void>;
    initialValues?: Partial<FormValues>;
    title: string;
    submitLabel: string;
  },
) {
  const { initialValues, availableHardwareModels } = props;

  const formik = useFormik<FormValues>({
    initialValues: {
      label: initialValues?.label ?? "",
      hwPlatformId:
        initialValues?.hwPlatformId ?? availableHardwareModels.at(0)?.id!,
    },
    async onSubmit(values) {
      try {
        await props.onSubmit({
          label: values.label,
          hwPlatform: { id: values.hwPlatformId },
        });
        props.revalidateView();
        props.onClose();
      } catch (error) {
        formik.setStatus(caughtValueToString(error));
      }
    },
  });

  return (
    <FormDialog {...props} form={formik}>
      <Stack direction="column" gap={4}>
        <TextField
          {...getTextFieldProps(formik, "label")}
          variant="standard"
          fullWidth
          label="Label"
        />

        <TextField
          {...getTextFieldProps(formik, "hwPlatformId")}
          variant="standard"
          fullWidth
          label="Hardware Platform"
          placeholder="Select hardware platform"
          select
        >
          {props.availableHardwareModels.map((deviceModel) => {
            return (
              <MenuItem value={deviceModel.id!}>
                {deviceModel.modelName}
              </MenuItem>
            );
          })}
        </TextField>
      </Stack>
    </FormDialog>
  );
}

export function CreateChargerDeviceDialog(
  props: Props & { gatewayDeviceId: number },
) {
  const client = useClient();

  return (
    <ChargerDeviceFormDialog
      {...props}
      title="Create Charger Device"
      submitLabel="Create"
      onSubmit={async (values) => {
        await unpackResponse(
          client.POST("/devices", {
            body: {
              ...values,
              parentDeviceId: props.gatewayDeviceId,
              deviceCategory: "CHARGER",
              status: "LEGACY",
              locationId: props.locationId,
            },
          }),
        );
      }}
    />
  );
}

export function UpdateChargerDeviceDialog(
  props: Props & { device: Schema["Device"] },
) {
  const client = useClient();

  return (
    <ChargerDeviceFormDialog
      {...props}
      initialValues={props.device}
      title="Update Charger Device"
      submitLabel="Save"
      onSubmit={async (values) => {
        await unpackResponse(
          client.PUT("/devices/{deviceId}", {
            params: { path: { deviceId: props.device.id! } },
            body: { ...props.device, ...values },
          }),
        );
      }}
    />
  );
}
