import { Box, Button, Stack, Typography } from "@mui/material";
import { ReactNode, createContext, useContext } from "react";
import { useMsalAccount } from "../auth/AccountContext";
import { useClientSWR } from "../client";
import { Schema } from "../client/types";
import { isInternalPMB } from "../constants/contactPositions";
import { useChatbase } from "./use-chatbase";

const UserInfoContext = createContext<
  (Schema["UserInfo"] & { revalidate: () => void }) | null
>(null);

export function useUserInfo() {
  const context = useContext(UserInfoContext);

  if (!context) {
    throw new Error("User Info not loaded.");
  }

  return context;
}

/**
 * Sets user info to context.
 *
 * Must be nested within `ClientProvider` at it is internally calling
 * the client to get user data.
 */
export function UserInfoProvider(props: { children: ReactNode }) {
  const { logout } = useMsalAccount();
  const { data, error, mutate } = useClientSWR("/userinfo");

  // Chatbase client should only be enabled for external users. If there is no data
  // we keep it disabled.
  const enableChatbase = data ? !isInternalPMB(data.principal.position) : false;
  useChatbase(enableChatbase);

  if (error) {
    return (
      <Box sx={{ padding: 4 }}>
        <Typography>
          Error loading user account. Try refreshing the page or log out and
          back in.
        </Typography>
        <Stack direction="row" sx={{ marginTop: 4 }} gap={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.location.reload()}
          >
            Refresh
          </Button>
          <Button variant="contained" color="info" onClick={() => logout()}>
            Log out
          </Button>
        </Stack>
      </Box>
    );
  }

  if (data) {
    return (
      <UserInfoContext.Provider value={{ ...data, revalidate: () => mutate() }}>
        {props.children}
      </UserInfoContext.Provider>
    );
  }

  return <div>Loading user account...</div>;
}
