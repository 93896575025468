import { Autocomplete, Grid, TextField } from "@mui/material";
import { FormikProvider, useFormik, useFormikContext } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";
import { useClient } from "../../client";
import {
  PMBAddress,
  PMBCompany,
  PMBLocationBase,
  Schema,
} from "../../client/types";
import FormikModal from "../../components/FormikModal";
import {
  countryFormatter,
  countryRequiresState,
} from "../../constants/countries";
import {
  getStatesOrProvincesByCountry,
  stateFormatter,
} from "../../constants/statesAndProvinces";
import { YupState, YupZip } from "../../utils/yup-validators";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  addressLineOne: Yup.string().required("Required"),
  addressLineTwo: Yup.string(),
  city: Yup.string().required("Required"),
  zipCode: YupZip(),
  country: Yup.string().required("Country is required"),
  state: YupState(),
});

function AddressModal(props: {
  company?: PMBCompany;
  location?: PMBLocationBase;
  open: boolean;
  toggleModal: () => void;
}) {
  const { values, touched, errors, setFieldValue } = useFormikContext<
    PMBAddress & { name: string }
  >();

  // When user select any other Country then [US, Canada] state is null
  useEffect(() => {
    if (values.state !== "N_A" && !countryRequiresState(values.country)) {
      setFieldValue("state", "N_A");
    }
  }, [setFieldValue, values.country, values.state]);

  return (
    <FormikModal
      title={"Edit mode"}
      submitBtnText={"Update"}
      open={props.open}
      handleClose={props.toggleModal}
    >
      <Grid container spacing={4} alignItems={"center"}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="name"
            name="name"
            label="Name"
            value={values.name}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
            onChange={(event) => setFieldValue("name", event.target.value)}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="addressLineOne"
            label="Address Line 1"
            value={values.addressLineOne}
            error={Boolean(touched.addressLineOne && errors.addressLineOne)}
            helperText={touched.addressLineOne && errors.addressLineOne}
            onChange={(event) =>
              setFieldValue("addressLineOne", event.target.value)
            }
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="addressLineTwo"
            label="Address Line 2"
            value={values.addressLineTwo}
            error={Boolean(touched.addressLineTwo && errors.addressLineTwo)}
            helperText={touched.addressLineTwo && errors.addressLineTwo}
            onChange={(event) =>
              setFieldValue("addressLineTwo", event.target.value)
            }
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={{ lg: 16, xs: 4 }}>
            <Grid item lg={10} xs={12}>
              <TextField
                fullWidth
                name="city"
                label="City"
                value={values.city}
                error={Boolean(touched.city && errors.city)}
                helperText={touched.city && errors.city}
                onChange={(event) => setFieldValue("city", event.target.value)}
                variant="standard"
              />
            </Grid>
            <Grid item lg={2} xs={12}>
              <TextField
                fullWidth
                name="zipCode"
                label="ZIP"
                value={values.zipCode}
                error={Boolean(touched.zipCode && errors.zipCode)}
                helperText={touched.zipCode && errors.zipCode}
                onChange={(event) =>
                  setFieldValue("zipCode", event.target.value)
                }
                variant="standard"
              />
            </Grid>
          </Grid>
        </Grid>

        {countryRequiresState(values.country) && (
          <Grid item xs={12}>
            <Autocomplete
              value={values.state}
              options={getStatesOrProvincesByCountry(values.country)}
              getOptionLabel={(option) => stateFormatter.format(option)}
              onChange={(_event, value) => {
                setFieldValue("state", value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="state"
                  label="State"
                  error={Boolean(touched.state && errors.state)}
                  helperText={touched.state && errors.state}
                  variant="standard"
                  fullWidth
                />
              )}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Autocomplete
            value={values.country}
            options={countryFormatter.enums}
            getOptionLabel={(option) => countryFormatter.format(option)}
            onChange={(_event, value) => {
              setFieldValue("country", value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name="country"
                label="Country"
                error={Boolean(touched.country && errors.country)}
                helperText={touched.country && errors.country}
                variant="standard"
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
    </FormikModal>
  );
}

export function CompanyAddressModal(props: {
  company: PMBCompany;
  modalOpened: boolean;
  toggleModal: () => void;
  updateCompany: (company: PMBCompany) => void;
  onSuccessUpdate: () => void;
}) {
  const client = useClient();

  const initialValues = {
    name: props.company.name,
    addressLineOne: props.company.address.addressLineOne,
    addressLineTwo: props.company.address.addressLineTwo ?? "",
    city: props.company.address.city,
    state: props.company.address.state ?? "",
    country: props.company.address.country,
    zipCode: props.company.address.zipCode,
  };

  const handleSubmit = async (
    values: PMBAddress & { name: string },
    { resetForm, setErrors, setStatus, setSubmitting }: any,
  ) => {
    try {
      if (props.company.primaryContact) {
        const data: Schema["UpdateCompany"] = {
          id: props.company.id,
          logo: props.company.logo,
          media: props.company.media,
          name: values.name,
          address: {
            addressLineOne: values.addressLineOne,
            addressLineTwo: values.addressLineTwo ?? "",
            city: values.city,
            state: values.state ?? "",
            country: values.country,
            zipCode: values.zipCode,
          },
          primaryContactId: props.company.primaryContact.id,
        };

        const result = await client.PUT("/companies/{companyId}", {
          params: { path: { companyId: props.company.id ?? 0 } },
          body: data,
        });

        if (result.error) {
          alert(result.error.message);
        }

        if (result.data) {
          resetForm();
          setStatus({ sent: true });
          setSubmitting(false);

          props.updateCompany(result.data);
          props.onSuccessUpdate();
          props.toggleModal();
        }
      } else {
        alert("Missing Primary Contact on Company.");
      }
    } catch (error: any) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema: validationSchema,
  });

  return (
    <FormikProvider value={formik}>
      <AddressModal
        company={props.company}
        open={props.modalOpened}
        toggleModal={props.toggleModal}
      />
    </FormikProvider>
  );
}

export function LocationAddressModal(props: {
  location: PMBLocationBase;
  modalOpened: boolean;
  toggleModal: () => void;
  updateLocation: (location: PMBLocationBase) => void;
  onSuccessUpdate: () => void;
}) {
  const client = useClient();

  const initialValues = {
    name: props.location.name,
    addressLineOne: props.location.address.addressLineOne,
    addressLineTwo: props.location.address.addressLineTwo ?? "",
    city: props.location.address.city,
    state: props.location.address.state ?? "",
    country: props.location.address.country,
    zipCode: props.location.address.zipCode,
  };

  const handleSubmit = async (
    values: PMBAddress & { name: string },
    { resetForm, setErrors, setStatus, setSubmitting }: any,
  ) => {
    try {
      const data: Schema["LocationBaseUpdate"] = {
        ...props.location,
        name: values.name,
        address: {
          addressLineOne: values.addressLineOne,
          addressLineTwo: values.addressLineTwo ?? "",
          city: values.city,
          state: values.state ?? "",
          country: values.country,
          zipCode: values.zipCode,
        },
        primaryContactId: props.location.primaryContact.id,
      };

      const result = await client.PUT("/locations/{locationId}", {
        params: { path: { locationId: props.location.id ?? 0 } },
        body: data,
      });

      if (result.error) {
        alert(result.error.message);
      }

      if (result.data) {
        props.updateLocation(result.data);
        props.onSuccessUpdate();
        props.toggleModal();

        resetForm();
        setStatus({ sent: true });
        setSubmitting(false);
      }
    } catch (error: any) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema: validationSchema,
  });

  return (
    <FormikProvider value={formik}>
      <AddressModal
        location={props.location}
        open={props.modalOpened}
        toggleModal={props.toggleModal}
      />
    </FormikProvider>
  );
}
