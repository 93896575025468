import { Chip } from "@mui/material";
import { useState } from "react";
import { useClientSWR } from "../../client";
import { isInternalPMB, position } from "../../constants/contactPositions";
import { contactStatus } from "../../constants/contactStatus";
import { isEveryPropNullish } from "../../utils/common";
import { CreateContactDialog, EditContactDialog } from "../contact-form-dialog";
import { ContactsFilterDialog, FilterValues } from "../contacts-filter-dialog";
import { ContactTable } from "../contacts-table";
import { DialogButton } from "../dialog-button";
import { FilterCard } from "../filter-card";
import { ContactFetchErrorCard } from "../info-card";
import { usePageable } from "../use-pageable";
import { ViewHeader } from "../view-header";

const internalPositions = position.enums.filter((position) =>
  isInternalPMB(position),
);

function getEmptyState(): FilterValues | (() => FilterValues) {
  return {
    name_search_term: undefined,
    email_search_term: undefined,
    positions: undefined,
    status: undefined,
  };
}

export function ContactListView() {
  const [filter, setFilter] = useState<FilterValues>(getEmptyState());
  const pageable = usePageable({ initialSortProp: "fullName" });
  const { data, error, mutate } = useClientSWR("/contacts", {
    params: { query: { ...pageable.query, ...filter } },
  });
  const revalidateView = () => mutate();

  const header = (
    <ViewHeader
      title="All Contacts"
      breadcrumb={[{ label: "Contacts" }]}
      button={
        <DialogButton
          variant="contained"
          color="primary"
          renderDialog={(dialogState) => {
            return (
              <CreateContactDialog
                {...dialogState}
                title="Add internal contact"
                positionOptions={internalPositions}
                onSuccess={revalidateView}
              />
            );
          }}
        >
          Add Contact
        </DialogButton>
      }
    />
  );

  if (error) {
    return (
      <>
        {header}
        <ContactFetchErrorCard error={error} />
      </>
    );
  }

  return (
    <>
      {header}

      <FilterCard
        filterActive={!isEveryPropNullish(filter)}
        initialStateLabel={"Showing all contacts"}
        onClearFilters={() => setFilter(getEmptyState())}
        renderFilterDialog={(dialogProps) => {
          return (
            <ContactsFilterDialog
              {...dialogProps}
              onSubmit={(values) => {
                setFilter(values);
                pageable.setPageNumber(0);
              }}
              initialValues={filter}
            />
          );
        }}
      >
        {filter.name_search_term && (
          <Chip
            label={`Name includes: ${filter.name_search_term}`}
            onDelete={() =>
              setFilter({ ...filter, name_search_term: undefined })
            }
          />
        )}
        {filter.email_search_term && (
          <Chip
            label={`Email includes: ${filter.email_search_term}`}
            onDelete={() =>
              setFilter({ ...filter, email_search_term: undefined })
            }
          />
        )}
        {filter.positions && filter.positions.length > 0 && (
          <Chip
            label={
              filter.positions.length === 1
                ? `Position: ${position.format(filter.positions[0])}`
                : `Position: Any of ${filter.positions.length} options`
            }
            onDelete={() => setFilter({ ...filter, positions: undefined })}
          />
        )}
        {filter.status && (
          <Chip
            label={`Status: ${contactStatus.format(filter.status)}`}
            onDelete={() => setFilter({ ...filter, status: undefined })}
          />
        )}
      </FilterCard>

      <ContactTable
        contacts={data}
        revalidateView={revalidateView}
        pageable={pageable}
        renderEditContactDialog={(dialog) => {
          return (
            <EditContactDialog
              {...dialog}
              onSuccess={revalidateView}
              positionOptions={internalPositions}
              positionDisabled={!isInternalPMB(dialog.contact.position)}
            />
          );
        }}
        columnsConfig={{
          contact: "30%",
          position: "15%",
          location: "15%",
          company: "15%",
          status: "15%",
        }}
      />
    </>
  );
}
