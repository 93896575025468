import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useClientSWR } from "../../client";
import { Schema } from "../../client/types";
import { CompanyFetchErrorCard } from "../info-card";
import LoadingSpinner from "../loading-spinner";
import { LocationSubscriptionDetailsCardFetch } from "../location-subscription-details-card";
import { ViewHeader } from "../view-header";

function CompanySubscriptionsView(props: {
  company: Schema["Company"];
  locations: Schema["LocationBase"][];
}) {
  return (
    <>
      <ViewHeader
        title={`${props.company.name} Subscriptions`}
        breadcrumb={[
          {
            label: "Companies",
            url: `/company/list`,
          },
          {
            label: props.company.name,
            url: `/company/${props.company.id}/profile`,
          },
          {
            label: "Subscriptions",
          },
        ]}
      />

      {
        <Grid container spacing={6}>
          {props.locations.map((location) => {
            return (
              <Grid item key={location.id} xs={12} lg={4}>
                <LocationSubscriptionDetailsCardFetch
                  locationId={location.id}
                  heading={location.name}
                />
              </Grid>
            );
          })}
        </Grid>
      }
    </>
  );
}

export function CompanySubscriptionsFetch() {
  const params = useParams<"companyId">();
  const companyId = Number(params.companyId);

  const companyR = useClientSWR("/companies/{companyId}", {
    params: { path: { companyId } },
  });
  const locationsR = useClientSWR("/companies/{companyId}/locations", {
    params: { path: { companyId }, query: { page_size: 1000 } },
  });

  const error = companyR.error || locationsR.error;

  if (error) {
    return <CompanyFetchErrorCard error={error} />;
  }

  if (!companyR.data || !locationsR.data) {
    return <LoadingSpinner />;
  }

  return (
    <CompanySubscriptionsView
      company={companyR.data}
      locations={locationsR.data.content}
    />
  );
}
