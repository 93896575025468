import { FilterAlt } from "@mui/icons-material";
import {
  ButtonGroup,
  Card,
  CardHeader,
  Chip,
  Grid,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useClientSWR } from "../client";
import { operations } from "../client/pmb";
import { Schema } from "../client/types";
import { paymentFrequency } from "../constants/paymentFrequency";
import { dateFunctions } from "../utils/date-functions";
import { formatCurrency, formatDate } from "../utils/formatters";
import { DialogButton } from "./dialog-button";
import { DummyRows } from "./dummy-rows";
import { ExportTransactionsDialog } from "./export-transactions-dialog";
import { TransactionFetchErrorCard } from "./info-card";
import { NavLinkButton } from "./nav-link-button";
import { PaymentDetailDialog } from "./payment-detail-dialog";
import { PaymentStatusChip } from "./payment-status-chip";
import { TableColumnHeadCell, TableRowHeadCell } from "./table-elements";
import {
  TransactionFilterDialog,
  transactionStatusFormatter,
} from "./transaction-filter-dialog";
import { Pageable, usePageable } from "./use-pageable";
import { ViewTable } from "./view-table";
import { ViewTablePagination } from "./view-table-pagination";

function AmountDueFrequencyCell(props: {
  amountDue: number;
  paymentFrequency: Schema["PaymentFrequency"];
}) {
  return (
    <TableRowHeadCell>
      <Grid
        container
        alignItems="center"
        gap={4}
        flexWrap="nowrap"
        minWidth={0}
      >
        <Grid item flexGrow={1} minWidth={0}>
          <Typography variant="body2" fontWeight="bold" component="span">
            {formatCurrency("USD", props.amountDue)}
          </Typography>
          <br />
          <Typography variant="body2" component="span">
            {paymentFrequency.format(props.paymentFrequency)}
          </Typography>
        </Grid>
      </Grid>
    </TableRowHeadCell>
  );
}

function DateCell(props: { date?: string }) {
  if (props.date) {
    return <TableCell>{formatDate(props.date, "medium")}</TableCell>;
  }

  return <TableCell>—</TableCell>;
}

function CompanyLocationCell(props: {
  companyName: string;
  locationName: string;
}) {
  return (
    <TableRowHeadCell>
      <Grid
        container
        alignItems="center"
        gap={4}
        flexWrap="nowrap"
        minWidth={0}
      >
        <Grid item flexGrow={1} minWidth={0}>
          <Typography variant="body2" fontWeight="bold" component="span">
            {props.locationName}
          </Typography>
          <br />
          <Typography variant="body2" component="span">
            {props.companyName}
          </Typography>
        </Grid>
      </Grid>
    </TableRowHeadCell>
  );
}

function Actions(props: Schema["PaymentListItem"]) {
  return (
    <TableCell align="right">
      <ButtonGroup sx={{ textAlign: "left" }}>
        <NavLinkButton
          variant="outlined"
          to={`/location/${props.locationId}/subscription`}
        >
          View subscription
        </NavLinkButton>
        <DialogButton
          variant="outlined"
          renderDialog={(dialogState) => {
            return (
              <PaymentDetailDialog {...dialogState} paymentId={props.id} />
            );
          }}
        >
          View
        </DialogButton>
      </ButtonGroup>
    </TableCell>
  );
}

function FilteredByChips(props: { filter: TransactionFilter }) {
  const dateFrom = props.filter?.due_date_from
    ? formatDate(props.filter?.due_date_from, "short")
    : "up to";
  const dateTo = props.filter?.due_date_to
    ? formatDate(props.filter?.due_date_to, "short")
    : "current";

  return (
    <Grid container gap={1} alignItems={"center"}>
      <Typography variant="subtitle2" pr={4}>
        Filtered by:
      </Typography>
      {props.filter?.customer_number && (
        <Chip
          label={"Customer number: " + props.filter?.customer_number}
          variant="outlined"
        />
      )}
      {(dateFrom !== "current" || dateTo !== "current") && (
        <Chip label={dateFrom + " - " + dateTo} variant="outlined" />
      )}
      {props.filter?.location_name && (
        <Chip
          label={"Location: " + props.filter?.location_name}
          variant="outlined"
        />
      )}
      {props.filter?.status && (
        <Chip
          label={transactionStatusFormatter.get(props.filter?.status)}
          variant="outlined"
        />
      )}
      {props.filter?.last_transaction_failed && (
        <Chip
          label={transactionStatusFormatter.get("FAILED")}
          variant="outlined"
        />
      )}
    </Grid>
  );
}

function LabeledValue(props: { label: string; value: number }) {
  return (
    <Typography variant="subtitle1" component="div">
      {props.label}:{" "}
      <Typography
        variant="subtitle1"
        component="span"
        style={{ color: "black" }}
      >
        {formatCurrency("USD", props.value)}
      </Typography>
    </Typography>
  );
}

function TransactionStatistics(props: {
  statistics?: Schema["PaymentTotalsResponse"];
}) {
  return (
    <Grid container columnGap={10} rowGap={2} pb={4}>
      {!props.statistics ? (
        <Typography variant="subtitle1" color={"warning"}>
          Loading statistics...
        </Typography>
      ) : (
        <>
          <LabeledValue label="Billed" value={props.statistics.billed} />
          <LabeledValue label="Collected" value={props.statistics.collected} />
          <LabeledValue label="Pending" value={props.statistics.pending} />
          <LabeledValue label="Overdue" value={props.statistics.overdue} />
          <LabeledValue label="Failed" value={props.statistics.failed} />
        </>
      )}
    </Grid>
  );
}

/**
 * TransactionTable is a stateless component which renders a list of
 * transactions if passed, otherwise a pending state of dummy data.
 */
function TransactionTable(props: {
  payments?: {
    totalElements?: number;
    content: Schema["PaymentListItem"][];
  };
  locationNames: string[];
  minWidth?: number;
  pageable: Pageable;
  filter?: TransactionFilter;
  handleFilterUpdate: (filter: TransactionFilter) => void;
}) {
  const ROW_HEIGHT = 100;
  const { minWidth, payments } = props;
  const { pageSize } = props.pageable;

  return (
    <Card>
      <CardHeader
        sx={{ pt: 6 }}
        action={
          <ButtonGroup>
            <DialogButton
              variant="outlined"
              renderDialog={(dialogState) => (
                <ExportTransactionsDialog
                  {...dialogState}
                  query={props.filter}
                />
              )}
            >
              Export
            </DialogButton>

            <DialogButton
              variant="outlined"
              renderDialog={(dialogState) => {
                return (
                  <TransactionFilterDialog
                    {...dialogState}
                    filter={props.filter}
                    handleFilterUpdate={props.handleFilterUpdate}
                    customerNumbers={
                      props.payments?.content.map(
                        (payment) => payment.customerNumber,
                      ) ?? []
                    }
                    locationNames={props.locationNames}
                  />
                );
              }}
            >
              Filter
              <FilterAlt sx={{ marginLeft: 1 }} fontSize="small" />
            </DialogButton>
          </ButtonGroup>
        }
        titleTypographyProps={{ variant: "h6" }}
        title={
          props.filter ? (
            <FilteredByChips filter={props.filter} />
          ) : (
            `${props.payments?.totalElements} ${
              props.payments?.totalElements && props.payments?.totalElements > 1
                ? "transactions"
                : "transaction"
            }`
          )
        }
      />
      <ViewTable
        minWidth={minWidth}
        head={
          <TableRow>
            <TableColumnHeadCell width="20%">
              Location / Company name
            </TableColumnHeadCell>
            <TableColumnHeadCell width="15%">
              Amount due / Frequency
            </TableColumnHeadCell>
            <TableColumnHeadCell width="15%">Due date</TableColumnHeadCell>
            <TableColumnHeadCell width="10%">Status</TableColumnHeadCell>
            <TableColumnHeadCell width="15%">Transaction</TableColumnHeadCell>
            <TableCell align="right" />
          </TableRow>
        }
        body={
          !payments ? (
            <DummyRows columns={6} rows={pageSize} rowHeight={ROW_HEIGHT} />
          ) : (
            payments.content.map((payment) => {
              return (
                <TableRow key={payment.id} sx={{ height: ROW_HEIGHT }} hover>
                  <CompanyLocationCell
                    companyName={payment.companyName}
                    locationName={payment.locationName}
                  />

                  <AmountDueFrequencyCell
                    amountDue={payment.amountDue}
                    paymentFrequency={payment.paymentFrequency}
                  />

                  <DateCell date={payment.dueDate} />

                  <TableCell>
                    <PaymentStatusChip {...payment} />
                  </TableCell>

                  <TableCell>
                    <TransactionInfo {...payment} />
                  </TableCell>

                  <Actions {...payment} />
                </TableRow>
              );
            })
          )
        }
        pagination={
          props.payments && (
            <ViewTablePagination
              {...props.pageable.paginationProps}
              totalElements={props.payments.totalElements}
            />
          )
        }
      />
    </Card>
  );
}

function TransactionInfo(props: Schema["PaymentListItem"]) {
  const { lastTransaction } = props;

  if (!lastTransaction) {
    return <>—</>;
  }

  return (
    <>
      ID: {lastTransaction.id}
      {lastTransaction.transactionDate && (
        <>
          <br />
          {formatDate(lastTransaction.transactionDate, "medium")}
        </>
      )}
    </>
  );
}

// OpenAPI type for transaction filter
export type TransactionFilter = Partial<
  operations["getPaymentsList"]["parameters"]["query"]
>;

/**
 * A stateful element that performs a request to the backend, handles errors and
 * pending states, and renders a table of companies once ready. It handles the
 * pagination and filtering.
 */
export function TransactionTableFetch() {
  const pageable = usePageable({ initialSortProp: "status" });

  // Filter should have default state, filter by current month
  const [filter, setFilter] = useState<TransactionFilter>({
    due_date_from: dateFunctions.startOfMonth.toISOString(),
    due_date_to: dateFunctions.endOfMonth.toISOString(),
  });

  const { data: locations, error: errorLocations } =
    useClientSWR("/locations/search");
  const { data: payments, error: errorPayments } = useClientSWR("/payments", {
    params: { query: { ...pageable.query, ...filter } },
  });
  const { data: statistics, error: errorStatistics } = useClientSWR(
    "/payments/totals",
    {
      params: { query: { ...filter } },
    },
  );

  const uniqueLocationNames = useMemo(() => {
    return (
      Array.from(new Set(locations?.map((location) => location.name ?? ""))) ??
      []
    );
  }, [locations]);

  if (errorLocations) {
    return <TransactionFetchErrorCard error={errorLocations} />;
  }

  if (errorPayments) {
    return <TransactionFetchErrorCard error={errorPayments} />;
  }

  if (errorStatistics) {
    return <TransactionFetchErrorCard error={errorStatistics} />;
  }

  const updateFilter = (newFilter: TransactionFilter) => {
    setFilter(newFilter);
  };

  return (
    <>
      <TransactionStatistics statistics={statistics} />
      <TransactionTable
        locationNames={uniqueLocationNames}
        payments={payments}
        pageable={pageable}
        filter={filter}
        handleFilterUpdate={updateFilter}
      />
    </>
  );
}
