import createClient, { FetchOptions } from "openapi-fetch";
import {
  ErrorResponse,
  FilterKeys,
  HasRequiredKeys,
  PathsWithMethod,
  ResponseObjectMap,
  SuccessResponse,
} from "openapi-typescript-helpers";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from "react";
import useSWR from "swr";
import { useMsalAccount } from "../auth/AccountContext";
import { publicConfig } from "../config";
import type { paths as Paths } from "./pmb";

export type PMBClient = ReturnType<typeof createClient<Paths>>;

// By default, we include a client that doesn't include the Bearer token in the
// Authorization header. This is useful when calling the API from anonymous routes.
const anonymousClient = createClient<Paths>({
  baseUrl: publicConfig.uri,
});

export const ClientContext = createContext<PMBClient>(anonymousClient);

export type UnknownOpenApiResponse = {
  data?: unknown;
  error?: unknown;
};

/**
 * Returns non-nullable data from provided PMB Client promise. Throws when
 * the error property is truthy or data is falsy.
 *
 * Use this to avoid repetitive error/data assertions from return values of
 * the OpenAPI client.
 *
 * Note that this is intended to be used with mutative methods (PUT, POST,
 * PATCH, DELETE, etc...). For fetches, use `useClientSWR` and render errors
 * as appropriate.
 *
 * @example
 * ```ts
 * const data = await unpackResponse(
 *  client.DELETE("/contacts/{id}/block", {
 *    params: { path: { id: contact.id } },
 *  }),
 *);
 * ```
 */
export async function unpackResponse<T extends UnknownOpenApiResponse>(
  promise: Promise<T>,
): Promise<NonNullable<T["data"]>> {
  const { data, error } = await promise;
  if (error) {
    throw error;
  }
  if (!data) {
    throw new Error(
      "Neither data nor error were returned from OpenAPI response.",
    );
  }
  return data;
}

/**
 * Returns PourMyBev client from context and guarantees that it
 * is non-null.
 *
 * Note that client has to be initialized via ClientProvider.
 */

export function useClient() {
  const client = useContext(ClientContext);

  if (!client) {
    throw new Error("PMB Client not initialized.");
  }

  return client;
}

/**
 * Uses the OpenAPI client in combination with SWR to easily and efficiently
 * fetch data. Note that only GET requests are supported.
 */
export function useClientSWR<P extends PathsWithMethod<Paths, "get">>(
  url: P,

  // This complicated-looking type determines whether the second parameter
  // should be required or optional.
  ...init: HasRequiredKeys<
    FetchOptions<FilterKeys<Paths[P], "get">>
  > extends never
    ? [(FetchOptions<FilterKeys<Paths[P], "get">> | undefined)?]
    : [FetchOptions<FilterKeys<Paths[P], "get">>]
) {
  const client = useClient();

  // The fetcher function has to be stabilized with useCallback to make
  // sure it doesn't change on every render.
  const fetcher = useCallback(
    async ([url, opts]: [any, any?]) => {
      const response = await (client as any).GET(url, {
        ...opts,
        headers: {
          ...opts?.headers,
          accept: "application/json",
        },
      });

      if (response.error) {
        throw response.error;
      }

      return response.data;
    },
    [client],
  ) as any;

  return useSWR<
    FilterKeys<
      SuccessResponse<ResponseObjectMap<Paths[P]["get"]>>,
      "application/json"
    >,
    FilterKeys<
      ErrorResponse<ResponseObjectMap<Paths[P]["get"]>>,
      "application/json"
    >
  >([url, ...init], fetcher);
}

/**
 * ClientProvider exposes an OpenAPI client to child components.
 *
 * Note that the provider must be nested within AccountProvider so
 * that the client can be correctly configured with the correct
 * token.
 */
export function ClientProvider(props: { children: ReactNode }) {
  const { token } = useMsalAccount();

  const authenticatedClient = useMemo(() => {
    return createClient<Paths>({
      baseUrl: publicConfig.uri,
      headers: { Authorization: `Bearer ${token}` },
    });
  }, [token]);

  return (
    <ClientContext.Provider value={authenticatedClient}>
      {props.children}
    </ClientContext.Provider>
  );
}
