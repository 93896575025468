import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Schema } from "../client/types";
import { formatPrice } from "../utils/formatters";
import { TableColumnHeadCell, TableRowHeadCell } from "./table-elements";

export function PaymentStructureTable(props: {
  paymentStructure: Schema["PaymentStructure"];
}) {
  const {
    items = [],
    discounts = [],
    cardPaymentFee,
    subTotalAmount = 0,
    totalAmount = 0,
  } = props.paymentStructure;

  const additionalItems = [
    ...discounts,
    ...(cardPaymentFee ? [cardPaymentFee] : []),
  ];

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableColumnHeadCell width="40%">
                <Typography variant="body2" fontWeight="bold" component="span">
                  Description
                </Typography>
              </TableColumnHeadCell>
              <TableColumnHeadCell width="20%" align="right">
                <Typography variant="body2" fontWeight="bold" component="span">
                  Quantity
                </Typography>
              </TableColumnHeadCell>
              <TableColumnHeadCell width="20%" align="right">
                <Typography variant="body2" fontWeight="bold" component="span">
                  Unit Price
                </Typography>
              </TableColumnHeadCell>
              <TableColumnHeadCell width="20%" align="right">
                <Typography variant="body2" fontWeight="bold" component="span">
                  Price
                </Typography>
              </TableColumnHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, index) => {
              return (
                <TableRow key={index}>
                  <TableRowHeadCell>{item.description}</TableRowHeadCell>
                  <TableCell align="right">{item.quantity}</TableCell>
                  <TableCell align="right">
                    {formatPrice(item.unitPrice, item.quantityMode)}
                  </TableCell>
                  <TableCell align="right">
                    {formatPrice(item.price, item.quantityMode)}
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell sx={{ borderBottom: "none" }}>
                <Typography component="div" fontWeight="bold">
                  Subtotal
                </Typography>
              </TableCell>
              <TableCell
                colSpan={3}
                align="right"
                sx={{ borderBottom: "none" }}
              >
                <Typography component="div" fontWeight="bold">
                  {formatPrice(subTotalAmount)}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {additionalItems.length > 0 && (
        <TableContainer sx={{ marginTop: 2 }}>
          <Table>
            <TableBody>
              {additionalItems.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableRowHeadCell width="40%">
                      {item.description}
                    </TableRowHeadCell>
                    <TableCell width="20%" align="right">
                      {formatPrice(item.quantity, item.quantityMode)}
                    </TableCell>
                    <TableCell align="right">
                      {formatPrice(item.price, "AMOUNT")}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Box
        sx={{
          marginTop: 8,
          marginLeft: 4,
          marginRight: 4,
          marginBottom: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: 3,
            backgroundColor: "background.default",
            borderRadius: 2,
          }}
        >
          <Typography variant="h5" component="div">
            Amount Due
          </Typography>
          <Typography variant="h5" component="div">
            {formatPrice(totalAmount)}
          </Typography>
        </Box>
      </Box>
    </>
  );
}
