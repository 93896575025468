import { useFormik } from "formik";
import { unpackResponse, useClient } from "../client";
import { Schema } from "../client/types";
import { caughtValueToString } from "../utils/caught-error";
import { FormDialog } from "./form-dialog";
import { getNumberFieldProps, NumberField } from "./number-field";

export function ApplyDiscountDialog(props: {
  isOpen: boolean;
  onClose: () => void;
  payment: Schema["PaymentListItem"];
  revalidateView: () => void;
}) {
  const { payment, ...dialogProps } = props;
  const client = useClient();

  const formik = useFormik({
    initialValues: {
      amount: payment.amountDue,
    },
    async onSubmit(values) {
      try {
        await unpackResponse(
          client.PATCH("/payments/{paymentId}/amount", {
            params: { path: { paymentId: payment.id } },
            body: { amount: values.amount },
          }),
        );
        props.revalidateView();
        props.onClose();
      } catch (error) {
        formik.setStatus(caughtValueToString(error));
      }
    },
  });

  return (
    <FormDialog
      {...dialogProps}
      form={formik}
      title={`Set new reinstatement fee value`}
      submitLabel="Save"
      submitColor="error"
    >
      <NumberField
        {...getNumberFieldProps(formik, "amount")}
        label="New amount"
        adornment="$"
        fullWidth
      />
    </FormDialog>
  );
}
