import { FormControl, MenuItem, TextField } from "@mui/material";
import { FormikValues, useFormik } from "formik";
import { ReactNode } from "react";

/**
 * Retrieves props from formik state that can be used by
 * `<SelectGroupField />`.
 */
export function getSelectGroupFieldProps<
  T extends FormikValues,
  Name extends string & keyof T,
>(formik: ReturnType<typeof useFormik<T>>, name: Name) {
  return {
    name,
    value: formik.values[name],
    error: formik.touched[name] && Boolean(formik.errors[name]),
    helperText: formik.touched[name] && formik.errors[name],
    onChange: (value: string) => formik.setFieldValue(name, value),
    onBlur: () => formik.setFieldTouched(name),
  };
}

/**
 * Represents a select group.
 *
 * Should be used with SelectGroupOption nested as children. Note that
 * it is up to you to make sure that the SelectGroupOptions are using
 * types that don't violate the SelectGroupField typings.
 *
 * If you are using this field with `formik`, you can use the
 * `getSelectGroupFieldProps` helper to retrieve exactly the right props
 * from formik state.
 */
export function SelectGroupField<V extends string>(props: {
  name: string;
  label: string;
  value: V;
  error?: boolean;
  helperText?: ReactNode;
  options: { label: string; value: V }[];
  onChange: (value: V) => void;
  onBlur?: () => void;
  variant?: "standard" | "outlined";
}) {
  return (
    <FormControl sx={{ display: "block" }}>
      <TextField
        sx={{ width: "100%" }}
        select
        name={props.name}
        id={props.name}
        value={props.value}
        label={props.label}
        onChange={(event) => props.onChange(event.target.value as V)}
        onBlur={props.onBlur}
        error={props.error}
        helperText={props.helperText}
        variant={props.variant ?? "standard"}
      >
        {props.options.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
}
