import { Card, CardHeader } from "@mui/material";
import { UserPlus } from "react-feather";
import { useClientSWR } from "../client";
import { Schema } from "../client/types";
import { AssignPrimaryContact } from "./assign-primary-contact-dialog";
import { CreateContactDialog, EditContactDialog } from "./contact-form-dialog";
import { ContactTable } from "./contacts-table";
import { DialogIconButton } from "./dialog-button";
import { LocationFetchErrorCard } from "./info-card";
import { RoleGuard } from "./role-guard";
import { usePageable } from "./use-pageable";

const externalPositions: Schema["Position"][] = [
  "LOCATION_STAFF",
  "LOCATION_MANAGER",
  "COMPANY_MANAGER",
];

export function LocationContactListCard(
  props: Schema["LocationBase"] & { revalidateView: () => void },
) {
  const { id: locationId, name: locationName } = props;
  const { id: companyId } = props.company;

  const pageable = usePageable({ initialSortProp: "fullName" });
  const { data, error, mutate } = useClientSWR(
    "/locations/{locationId}/contacts",
    {
      params: {
        path: { locationId },
        query: pageable.query,
      },
    },
  );
  const revalidateContactListView = () => mutate();

  const header = (
    <CardHeader
      title="Location contacts"
      titleTypographyProps={{ variant: "h5", component: "h2" }}
      action={
        <RoleGuard
          allowedRoles={[
            "PMB_ADMIN",
            "PMB_VIEWER",
            "COMPANY_ADMIN",
            "LOCATION_ADMIN",
          ]}
        >
          <DialogIconButton
            label="Add location contact"
            renderDialog={(dialogProps) => {
              return (
                <CreateContactDialog
                  {...dialogProps}
                  title="Add location contact"
                  onSuccess={revalidateContactListView}
                  positionOptions={externalPositions.reverse()}
                  contactScope={{ locationId, companyId }}
                />
              );
            }}
          >
            <UserPlus />
          </DialogIconButton>
        </RoleGuard>
      }
    />
  );

  if (error) {
    return (
      <Card>
        {header}
        <LocationFetchErrorCard error={error} />;
      </Card>
    );
  }

  return (
    <Card>
      {header}
      <ContactTable
        contacts={data}
        pageable={pageable}
        revalidateView={revalidateContactListView}
        renderEditContactDialog={(dialog) => {
          return (
            <EditContactDialog
              {...dialog}
              onSuccess={revalidateContactListView}
              positionOptions={externalPositions}
            />
          );
        }}
        renderAssignPrimaryContactDialog={(dialog) => {
          return (
            <AssignPrimaryContact
              {...dialog}
              onSuccess={() => {
                // When primary contact changes, we need to revalidate the location view, as that
                // is where the information about primary contact is held.
                props.revalidateView();
              }}
              subjectName={locationName}
              onSubmit={(client) => {
                return client.PATCH("/locations/{locationId}", {
                  params: { path: { locationId } },
                  body: { primaryContactId: dialog.contact.id },
                });
              }}
            />
          );
        }}
        getIsPrimaryContact={(contact) => {
          return props.primaryContact?.id === contact.id;
        }}
        getCanBecomePrimaryContact={(contact) => {
          // Only company/location managers can become primary contacts of locations
          const allowedRoles: Schema["Position"][] = [
            "COMPANY_MANAGER",
            "LOCATION_MANAGER",
          ];
          return allowedRoles.includes(contact.position);
        }}
        columnsConfig={{
          contact: "35%",
          position: "25%",
          location: null,
          company: null,
          status: "25%",
        }}
      />
    </Card>
  );
}
