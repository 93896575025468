import { Chip } from "@mui/material";
import { useState } from "react";
import { locationStatus } from "../../constants/locationStatus";
import { isEveryPropNullish } from "../../utils/common";
import { ExportLocationsDialog } from "../export-locations-dialog";
import { FilterCard } from "../filter-card";
import {
  FormValues,
  LocationFilterDialog,
  valuesToQuery,
} from "../locations-filter-dialog";
import { LocationsTableFetch } from "../locations-table";
import { NavLinkButton } from "../nav-link-button";
import { usePageable } from "../use-pageable";
import { ViewHeader } from "../view-header";

function getInitialState(): FormValues {
  return {
    locationStatus: null,
    billingStatus: null,
    salesRep: null,
    projectManager: null,
    company: null,
    subscriptionStatus: null,
  };
}

export function LocationListView() {
  const pageable = usePageable({ initialSortProp: "name" });
  const [values, setValues] = useState<FormValues>(getInitialState);
  const filtersActive = !isEveryPropNullish(values);

  return (
    <>
      <ViewHeader
        title="All Locations"
        breadcrumb={[{ label: "Locations" }]}
        button={
          <NavLinkButton
            to="/location/create"
            variant="contained"
            color="primary"
          >
            Add Location
          </NavLinkButton>
        }
      />

      <FilterCard
        initialStateLabel="Showing all locations"
        filterActive={filtersActive}
        onClearFilters={() => setValues(getInitialState())}
        renderFilterDialog={(dialogProps) => {
          return (
            <LocationFilterDialog
              {...dialogProps}
              initialValues={values}
              onSubmit={(values) => {
                setValues(values);

                // We must set page number to 0 when filters change so that when a new filter is
                // set, if it has fewer results/pages than the previous, we don't end up on a
                // page with no results.
                pageable.setPageNumber(0);
              }}
            />
          );
        }}
        renderExportDialog={(dialogProps) => {
          return (
            <ExportLocationsDialog
              {...dialogProps}
              query={valuesToQuery(values)}
            />
          );
        }}
      >
        {values.locationStatus && (
          <Chip
            label={`Location status: ${locationStatus.format(
              values.locationStatus,
            )}`}
            onDelete={() => {
              setValues({ ...values, locationStatus: null });
            }}
          />
        )}

        {values.salesRep && (
          <Chip
            label={`Sales Rep: ${values.salesRep.label}`}
            onDelete={() => {
              setValues({ ...values, salesRep: null });
            }}
          />
        )}

        {values.projectManager && (
          <Chip
            label={`Project Manager: ${values.projectManager.label}`}
            onDelete={() => {
              setValues({ ...values, projectManager: null });
            }}
          />
        )}

        {values.company && (
          <Chip
            label={`Company: ${values.company.label}`}
            onDelete={() => {
              setValues({ ...values, company: null });
            }}
          />
        )}

        {values.subscriptionStatus && (
          <Chip
            label={`Locations with manual billing`}
            onDelete={() => {
              setValues({ ...values, subscriptionStatus: null });
            }}
          />
        )}
      </FilterCard>

      <LocationsTableFetch query={valuesToQuery(values)} pageable={pageable} />
    </>
  );
}
