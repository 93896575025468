import { Autocomplete, Box, TextField } from "@mui/material";
import { useFormik } from "formik";
import { ReactNode } from "react";
import { object, string } from "yup";
import { UnknownOpenApiResponse, unpackResponse, useClient } from "../client";
import { Schema } from "../client/types";
import { position } from "../constants/contactPositions";
import { caughtValueToString } from "../utils/caught-error";
import { FormDialog } from "./form-dialog";
import { getTextFieldProps } from "./text-field";

type ContactFormDialogValues = Pick<
  Schema["Contact"],
  "fullName" | "position" | "mobile" | "email"
>;

type ContactScope = {
  locationId?: number;
  companyId?: number;
};

const validationSchema = object().shape({
  fullName: string().required("Required"),
  position: string().required("Required"),
  mobile: string().required("Required"),
  email: string().email().required("Required"),
});

function isEmailDisabled(status: Schema["UserStatus"]) {
  const nonEditableStates: Schema["UserStatus"][] = ["ACTIVE", "BLOCKED"];
  return !!nonEditableStates.includes(status);
}

function ContactFormDialog<T extends UnknownOpenApiResponse>(props: {
  title: string;
  submitLabel: string;
  isOpen: boolean;
  onSubmit: (values: ContactFormDialogValues) => Promise<T>;
  onClose: () => void;
  onSuccess: () => void;
  contact?: Schema["Contact"];
  positionOptions: Schema["Position"][];
  positionDisabled?: boolean;
  emailEnabled?: boolean;
  note?: ReactNode;
}) {
  const {
    contact,
    positionOptions,
    positionDisabled,

    // Unless explicitly set, email editing should be fallback to disabled
    emailEnabled,
  } = props;

  const formik = useFormik<ContactFormDialogValues>({
    initialValues: {
      fullName: contact?.fullName ?? "",
      position: contact?.position ?? positionOptions[0],
      mobile: contact?.mobile ?? "",
      email: contact?.email ?? "",
    },
    validationSchema,
    async onSubmit(values) {
      try {
        await unpackResponse(props.onSubmit(values));

        props.onSuccess();
        props.onClose();
      } catch (error) {
        formik.setStatus(caughtValueToString(error));
      }
    },
  });

  const { value, onChange, ...rest } = getTextFieldProps(formik, "position");

  return (
    <FormDialog {...props} form={formik}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <TextField
          {...getTextFieldProps(formik, "fullName")}
          label="Name"
          variant="standard"
          fullWidth
        />
        <TextField
          {...getTextFieldProps(formik, "mobile")}
          label="Mobile"
          variant="standard"
          fullWidth
        />
        <TextField
          {...getTextFieldProps(formik, "email")}
          disabled={
            // Explicitly enabling email editing takes precedence over other logic
            emailEnabled
              ? false
              : // If we do not have a refernece contact it means we are creating a
                // new contact and therefore the email should be editable.
                contact?.userStatus
                ? isEmailDisabled(contact.userStatus)
                : false
          }
          label="Email"
          variant="standard"
          fullWidth
        />
        <Autocomplete
          value={value}
          disabled={positionDisabled}
          options={positionOptions}
          getOptionLabel={(option) => position.format(option)}
          onChange={(_, value) => {
            formik.setFieldValue("position", value!);
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                name={rest.name}
                onBlur={rest.onBlur}
                label="Position"
                variant="standard"
                fullWidth
              />
            );
          }}
        />
        {props.note}
      </Box>
    </FormDialog>
  );
}

function getCreateContactBody(
  values: ContactFormDialogValues,
  scope?: ContactScope,
): Schema["ContactUpdateProperties"] {
  switch (values.position) {
    case "COMPANY_MANAGER": {
      return {
        ...values,
        companyId: scope?.companyId,
      };
    }

    case "LOCATION_MANAGER":
    case "LOCATION_STAFF": {
      return {
        ...values,
        locationId: scope?.locationId,
      };
    }

    default: {
      return values;
    }
  }
}

export function CreateContactDialog(props: {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  positionOptions: Schema["Position"][];
  contactScope?: ContactScope;
}) {
  const client = useClient();

  return (
    <ContactFormDialog
      {...props}
      positionDisabled={false}
      submitLabel="Add"
      onSubmit={(values) => {
        return client.POST("/contacts", {
          body: getCreateContactBody(values, props.contactScope),
        });
      }}
    />
  );
}

export function EditContactDialog(props: {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  contact: Schema["Contact"];
  positionOptions: Schema["Position"][];
  positionDisabled?: boolean;
  emailEnabled?: boolean;
  note?: ReactNode;
}) {
  const client = useClient();
  const { contact } = props;

  return (
    <ContactFormDialog
      {...props}
      title="Edit contact"
      submitLabel="Save"
      onSubmit={(values) => {
        return client.PUT("/contacts/{contactId}", {
          params: { path: { contactId: props.contact.id } },
          body: { ...contact, ...values },
        });
      }}
    />
  );
}
