import { Button, Typography } from "@mui/material";
import { Gift } from "react-feather";
import { Link } from "react-router-dom";
import { Schema } from "../client/types";
import { useCompanySubscriptions } from "../hooks/useCompanySubscriptions";
import { countBy } from "../utils/common";
import { EnumMapper } from "../utils/enum-formatter";
import { MessageFormat, mf } from "../utils/formatters";
import { DummyLine } from "./dummy-line";
import { TextWithIcon } from "./issue-text";
import { useUserHasRole } from "./role-guard";
import { ViewCard } from "./view-card";

const content = {
  heading: `Subscription details`,
  subscriptionsCount: mf(
    `{count, plural, =1 {1 subscription} other {# subscriptions}}`,
  ),
  ok: `Everything in order.`,
  fetchError: `Failed to fetch subscription details.`,
  viewButtonLabel: `View details`,
  annualPromo: mf(
    `{locationsCount, plural, =1 {One of your locations is using} other {# of your locations are using}} monthly billing. Switch to annual and save 10%.`,
  ),

  warnings: new EnumMapper<Schema["SubscriptionWarningType"], MessageFormat>(
    {
      MISSING_PAYMENT_METHOD: mf(
        `Missing payment method for {count, plural, =1 {one location} other {# locations}}.`,
      ),
      PAYMENT_OVERDUE: mf(
        `Overdue payment for {count, plural, =1 {one location} other {# locations}}.`,
      ),
      MISSING_PRIMARY_CONTACT: mf(
        `Missing primary contact for {count, plural, =1 {one location} other {# locations}}.`,
      ),
      LOCATION_NOT_SUPPORTED: mf(
        `{count, plural, =1 {One location} other {# locations}} not supported.`,
      ),
    },
    mf(`Unknown warning.`),
  ),
};

function SubscriptionDetailsContent(props: {
  subscriptions: Schema["Subscription"][];
}) {
  const isCompanyAdmin = useUserHasRole(["COMPANY_ADMIN"]);
  const warnings = props.subscriptions.flatMap((s) => s.warnings ?? []);
  const paymentFrequencies = props.subscriptions.map((s) => s.paymentFrequency);
  const uniqueWarnings = Array.from(new Set(warnings));
  const warningsCountByType = countBy(warnings, (w) => w);

  if (warnings.length > 0) {
    return (
      <>
        {uniqueWarnings.map((warning) => {
          const message = content.warnings.get(warning);

          return (
            <TextWithIcon key={warning}>
              {typeof message === "string"
                ? message
                : message.format({ count: warningsCountByType[warning] ?? 0 })}
            </TextWithIcon>
          );
        })}
      </>
    );
  }

  if (isCompanyAdmin && paymentFrequencies.includes("MONTHLY")) {
    return (
      <TextWithIcon icon={<Gift size="1em" />} color="success.main">
        {content.annualPromo.format({
          locationsCount: paymentFrequencies.reduce(
            (acc, freq) => (freq === "MONTHLY" ? acc + 1 : acc),
            0,
          ),
        })}
      </TextWithIcon>
    );
  }

  return <Typography variant="body2">{content.ok}</Typography>;
}

export function CompanySubscriptionDetailsCard(props: {
  companyId: number;
  subscriptions?: Schema["Subscription"][];
  error?: Schema["Error"];
}) {
  const commonProps = {
    title: content.heading,
    actions: (
      <Button
        variant="outlined"
        component={Link}
        to={`/company/${props.companyId}/subscriptions`}
      >
        {content.viewButtonLabel}
      </Button>
    ),
  };

  if (props.error) {
    return (
      <ViewCard
        {...commonProps}
        content={<Typography variant="body2">{content.fetchError}</Typography>}
      />
    );
  }

  if (!props.subscriptions) {
    return (
      <ViewCard
        {...commonProps}
        subtitle={<DummyLine width="60%" />}
        content={<DummyLine width="70%" />}
      />
    );
  }

  return (
    <ViewCard
      {...commonProps}
      subtitle={content.subscriptionsCount.format({
        count: props.subscriptions.length,
      })}
      content={
        <SubscriptionDetailsContent subscriptions={props.subscriptions ?? []} />
      }
    />
  );
}

export function CompanySubscriptionDetailsCardFetch(props: {
  companyId: number;
}) {
  const { data, error } = useCompanySubscriptions(props.companyId);

  return (
    <CompanySubscriptionDetailsCard
      companyId={props.companyId}
      subscriptions={data}
      error={error}
    />
  );
}
