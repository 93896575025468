import { Box, Button, Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useClientSWR } from "../../client";
import { Schema } from "../../client/types";
import { ErrorCard } from "../../ui/info-card";
import LoadingSpinner from "../../ui/loading-spinner";
import { MoreMenu, MoreMenuDialogItem } from "../../ui/more-menu";
import { RoleGuard } from "../../ui/role-guard";
import { ViewHeader } from "../../ui/view-header";
import { groupBy } from "../../utils/common";
import { CreateChargerDeviceDialog } from "./charger-form-dialog";
import {
  ChargerDeviceCard,
  DeviceGroupCard,
  GatewayDeviceCard,
  ScreenDeviceCard,
} from "./device-cards";
import { CreateDeviceGroupDialog } from "./device-group-form-dialog";
import { CreateGatewayDeviceDialog } from "./gateway-device-form-dialog";
import { CreateScreenDeviceDialog } from "./screen-device-form-dialog";

function LocationTopologyView(props: {
  location: Schema["LocationBase"];
  devices: Schema["Device"][];
  deviceModels: Schema["DeviceModel"][];
  deviceGroups: Schema["DeviceGroup"][];
  addons: Schema["Addon"][];
  revalidateView: () => void;
}) {
  const { location, devices, deviceGroups, deviceModels } = props;
  const devicesByCategory = groupBy(devices, (d) => d.deviceCategory);
  const gatewayDevice = devicesByCategory.GATEWAY.at(0);
  const standaloneScreens = devicesByCategory.SCREEN.filter(
    (screen) => !screen.deviceGroup?.id,
  );
  const deviceModelsByCategory = groupBy(deviceModels, (d) => d.modelCategory);

  const addDevicesButton = (
    <RoleGuard allowedRoles={["PMB_ADMIN"]}>
      <MoreMenu
        renderButton={(buttonProps) => (
          <Button {...buttonProps} variant="contained">
            Add device...
          </Button>
        )}
      >
        <MoreMenuDialogItem
          disabled={!!gatewayDevice}
          renderDialog={(dialogProps) => {
            return (
              <CreateGatewayDeviceDialog
                {...dialogProps}
                locationId={location.id}
                availableHardwareModels={deviceModelsByCategory.GATEWAY}
                revalidateView={props.revalidateView}
              />
            );
          }}
        >
          Gateway
        </MoreMenuDialogItem>

        <MoreMenuDialogItem
          disabled={!gatewayDevice}
          renderDialog={(dialogProps) => {
            return (
              <CreateChargerDeviceDialog
                {...dialogProps}
                locationId={location.id}
                gatewayDeviceId={gatewayDevice?.id!}
                availableHardwareModels={deviceModelsByCategory.CHARGER}
                revalidateView={props.revalidateView}
              />
            );
          }}
        >
          Charger
        </MoreMenuDialogItem>
        <MoreMenuDialogItem
          disabled={!gatewayDevice}
          renderDialog={(dialogProps) => {
            return (
              <CreateScreenDeviceDialog
                {...dialogProps}
                deviceModelsByCategory={deviceModelsByCategory}
                gatewayDeviceId={gatewayDevice?.id!}
                locationId={location.id}
                revalidateView={props.revalidateView}
              />
            );
          }}
        >
          Screen
        </MoreMenuDialogItem>

        <MoreMenuDialogItem
          disabled={!gatewayDevice}
          renderDialog={(dialogProps) => {
            return (
              <CreateDeviceGroupDialog
                {...dialogProps}
                locationId={location.id}
                gatewayDeviceId={gatewayDevice!.id!}
                revalidateView={props.revalidateView}
                addons={props.addons.filter(
                  (addon) =>
                    addon.type === "TAP_TRAILER" ||
                    addon.type === "SELF_POUR_STATION",
                )}
              />
            );
          }}
        >
          Device group
        </MoreMenuDialogItem>
      </MoreMenu>
    </RoleGuard>
  );

  const breadcrumb = [
    { label: "Locations", url: `/location/list` },
    { label: location.name, url: `/location/${location.id}/profile` },
    { label: "Topology" },
  ];

  const header = (
    <ViewHeader
      title={`${location.name} Topology`}
      button={addDevicesButton}
      breadcrumb={breadcrumb}
    />
  );

  if (devices.length === 0) {
    return (
      <>
        {header}

        <Box>
          <Typography variant="body2">No devices added yet.</Typography>
        </Box>
      </>
    );
  }

  return (
    <>
      {header}

      <Stack gap={8}>
        {devicesByCategory.GATEWAY.map((device) => {
          return (
            <GatewayDeviceCard
              key={device.id}
              device={device}
              revalidateView={props.revalidateView}
              locationId={location.id}
              availableHardwareModels={deviceModelsByCategory.GATEWAY}
            />
          );
        })}

        {devicesByCategory.CHARGER.length > 0 && (
          <Stack gap={4}>
            <Typography variant="h5">Chargers</Typography>
            {devicesByCategory.CHARGER.map((device) => {
              return (
                <ChargerDeviceCard
                  key={device.id}
                  device={device}
                  revalidateView={props.revalidateView}
                  locationId={location.id}
                  availableHardwareModels={deviceModelsByCategory.CHARGER}
                />
              );
            })}
          </Stack>
        )}

        {deviceGroups.length > 0 && (
          <Stack gap={4}>
            <Typography variant="h5">Device Groups</Typography>

            {deviceGroups.map((dg) => {
              return (
                <DeviceGroupCard
                  key={dg.id}
                  {...dg}
                  locationId={location.id}
                  revalidateView={props.revalidateView}
                />
              );
            })}
          </Stack>
        )}

        {standaloneScreens.length > 0 && (
          <Stack gap={4}>
            <Typography variant="h5">Screens</Typography>

            {standaloneScreens.map((device) => {
              return (
                <ScreenDeviceCard
                  deviceModelsByCategory={deviceModelsByCategory}
                  key={device.id}
                  device={device}
                  revalidateView={props.revalidateView}
                  locationId={location.id}
                />
              );
            })}
          </Stack>
        )}
      </Stack>
    </>
  );
}

export function LocationTopologyFetch() {
  const params = useParams<"locationId">();
  const locationId = parseInt(params.locationId!, 10);
  const {
    data: location,
    error: locationError,
    mutate: mutateLocation,
  } = useClientSWR("/locations/{locationId}", {
    params: { path: { locationId } },
  });

  const {
    data: devices,
    error: devicesError,
    mutate: mutateDevices,
  } = useClientSWR("/locations/{locationId}/devices", {
    params: { path: { locationId }, query: {} },
  });

  const {
    data: deviceGroupsPage,
    error: deviceGroupsError,
    mutate: mutateDeviceGroups,
  } = useClientSWR("/locations/{locationId}/device-groups", {
    params: { path: { locationId }, query: { page_size: 1000 } },
  });

  const { data: deviceModels, error: devicesModelsError } =
    useClientSWR("/devices/models");

  const { data: addons, error: addonsError } = useClientSWR("/addons");

  if (location && devices && deviceModels && deviceGroupsPage && addons) {
    const revalidateView = () => {
      mutateLocation();
      mutateDevices();
      mutateDeviceGroups();
    };

    return (
      <LocationTopologyView
        location={location}
        devices={devices}
        deviceModels={deviceModels}
        deviceGroups={deviceGroupsPage.content}
        addons={addons}
        revalidateView={revalidateView}
      />
    );
  }

  const error =
    locationError ||
    devicesError ||
    devicesModelsError ||
    deviceGroupsError ||
    addonsError;
  if (error) {
    return (
      <ErrorCard
        heading="Error while fetching location devices data"
        message={error?.message || devicesError?.message}
      />
    );
  }

  return <LoadingSpinner />;
}
