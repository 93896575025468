import { Card, CardHeader } from "@mui/material";
import { UserPlus } from "react-feather";
import { useClientSWR } from "../client";
import { Schema } from "../client/types";
import { AssignPrimaryContact } from "./assign-primary-contact-dialog";
import { CreateContactDialog, EditContactDialog } from "./contact-form-dialog";
import { ContactTable } from "./contacts-table";
import { DialogIconButton } from "./dialog-button";
import { ContactFetchErrorCard } from "./info-card";
import { RoleGuard } from "./role-guard";
import { usePageable } from "./use-pageable";

const positionOptions: Schema["Position"][] = ["COMPANY_MANAGER"];

export function CompanyContactListCard(
  props: Schema["Company"] & { revalidateView: () => void },
) {
  const { id: companyId, name: companyName } = props;

  const pageable = usePageable({ initialSortProp: "fullName" });
  const { data, error, mutate } = useClientSWR(
    "/companies/{companyId}/contacts",
    { params: { path: { companyId }, query: pageable.query } },
  );
  const revalidateContactListView = () => mutate();

  const header = (
    <CardHeader
      title="Company Contacts"
      titleTypographyProps={{ variant: "h5", component: "h2" }}
      action={
        <RoleGuard allowedRoles={["PMB_ADMIN", "PMB_VIEWER", "COMPANY_ADMIN"]}>
          <DialogIconButton
            label="Add company contact"
            renderDialog={(dialogProps) => {
              return (
                <CreateContactDialog
                  {...dialogProps}
                  title="Add company contact"
                  positionOptions={positionOptions}
                  contactScope={{ companyId }}
                  onSuccess={revalidateContactListView}
                />
              );
            }}
          >
            <UserPlus />
          </DialogIconButton>
        </RoleGuard>
      }
    />
  );

  if (error) {
    return (
      <Card>
        {header}
        <ContactFetchErrorCard error={error} />;
      </Card>
    );
  }

  return (
    <Card>
      {header}
      <ContactTable
        contacts={data}
        pageable={pageable}
        revalidateView={revalidateContactListView}
        renderEditContactDialog={(dialog) => {
          return (
            <EditContactDialog
              {...dialog}
              onSuccess={revalidateContactListView}
              positionOptions={positionOptions}
            />
          );
        }}
        renderAssignPrimaryContactDialog={(dialog) => {
          return (
            <AssignPrimaryContact
              {...dialog}
              onSuccess={() => {
                // When primary contact changes, we need to revalidate the company view, as that
                // is where the information about primary contact is held.
                props.revalidateView();
              }}
              subjectName={companyName}
              onSubmit={(client) => {
                return client.PATCH("/companies/{companyId}", {
                  params: { path: { companyId } },
                  body: { primaryContactId: dialog.contact.id },
                });
              }}
            />
          );
        }}
        getIsPrimaryContact={(contact) => {
          return props.primaryContact?.id === contact.id;
        }}
        getCanBecomePrimaryContact={(contact) => {
          // Only company managers can become primary contacts of companies
          return contact.position === "COMPANY_MANAGER";
        }}
        columnsConfig={{
          contact: "30%",
          position: "20%",
          location: "20%",
          company: null,
          status: "20%",
        }}
      />
    </Card>
  );
}
