import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useFormik } from "formik";
import { useClientSWR } from "../client";
import { Schema } from "../client/types";
import { EnumFormatter } from "../utils/enum-formatter";
import { TransactionFetchErrorCard } from "./info-card";
import { TransactionFilter } from "./transaction-table";
import { ViewDialog } from "./view-dialog";

export const transactionStatusFormatter = new EnumFormatter<
  Schema["PaymentStatus"] | Schema["PaymentTransactionStatus"]
>({
  PAID: "Paid",
  PENDING: "Pending",
  DUE: "Payment due",
  MANUALLY_SETTLED: "Manually settled",
  OVERDUE: "Overdue",
  FAILED: "Failed",
  SUCCESS: "Success",
});

function TransactionStatusChip(props: {
  label: string;
  active: boolean;
  onClick: () => void;
}) {
  return (
    <Chip
      label={props.label}
      variant="filled"
      color={props.active ? "primary" : "default"}
      onClick={props.onClick}
    />
  );
}

export function TransactionFilterDialog(props: {
  locationNames: string[];
  customerNumbers: string[];
  isOpen: boolean;
  onClose: () => void;
  filter: TransactionFilter;
  handleFilterUpdate: (filter: TransactionFilter) => void;
}) {
  const handleSubmit = (values: TransactionFilter) => {
    // In case there is no active filter make filter undefined
    if (
      !values?.customer_number &&
      !values?.due_date_from &&
      !values?.due_date_to &&
      !values?.location_name &&
      !values?.status &&
      !values?.last_transaction_failed
    ) {
      props.handleFilterUpdate(undefined);
    } else {
      props.handleFilterUpdate(values);
    }
    props.onClose();
  };

  const formik = useFormik({
    initialValues: { ...props.filter },
    onSubmit: handleSubmit,
  });

  const { data, error, mutate } = useClientSWR("/payments", {
    params: { query: formik.values },
  });

  if (error) {
    return <TransactionFetchErrorCard error={error} />;
  }

  // Initial values changes during the filtration process, need to be explicitly cleared.
  const clearForm = () => {
    formik.setFieldValue("customer_number", undefined);
    formik.setFieldValue("due_date_from", undefined);
    formik.setFieldValue("due_date_to", undefined);
    formik.setFieldValue("location_name", undefined);
    formik.setFieldValue("last_transaction_failed", undefined);
    formik.setFieldValue("status", undefined);
  };

  function clearStatus() {
    formik.setFieldValue("status", undefined);
    formik.setFieldValue("last_transaction_failed", undefined);

    mutate();
  }

  type CompositeStatus = {
    status: Schema["PaymentStatus"];
    lastTransactionFailed: boolean;
  };

  function isActive(values: CompositeStatus) {
    return (
      formik.values.status === values.status &&
      formik.values.last_transaction_failed === values.lastTransactionFailed
    );
  }

  function toggleStatus(values: CompositeStatus) {
    return function handleClick() {
      if (!isActive(values)) {
        formik.setFieldValue("status", values.status);
        formik.setFieldValue(
          "last_transaction_failed",
          values.lastTransactionFailed,
        );
      } else {
        formik.setFieldValue("status", undefined);
        formik.setFieldValue("last_transaction_failed", undefined);
      }

      mutate();
    };
  }

  function getStatusFieldProps(values: {
    status: Schema["PaymentStatus"];
    lastTransactionFailed: boolean;
  }) {
    return {
      label: transactionStatusFormatter.format(values.status),
      active: isActive(values),
      onClick: toggleStatus(values),
    };
  }

  return (
    <ViewDialog
      {...props}
      title="Filter by"
      padding={9}
      buttonVariant="contained"
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
        <form onSubmit={formik.handleSubmit}>
          <Box pb={4}>
            <Typography variant="subtitle1">Due date</Typography>
            <Typography variant="body2" pb={2}>
              List transactions within a defined date range:
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
              <DatePicker
                label="Date from"
                value={
                  formik.values.due_date_from
                    ? new Date(formik.values.due_date_from)
                    : null
                }
                onChange={(date) =>
                  formik.setFieldValue("due_date_from", date?.toISOString())
                }
                maxDate={
                  formik.values.due_date_to
                    ? new Date(formik.values.due_date_to)
                    : null
                }
                sx={{ my: 2, width: "100%" }}
              />
              <DatePicker
                label="Date to"
                value={
                  formik.values.due_date_to
                    ? new Date(formik.values.due_date_to)
                    : null
                }
                onChange={(date) =>
                  formik.setFieldValue("due_date_to", date?.toISOString())
                }
                minDate={
                  formik.values.due_date_from
                    ? new Date(formik.values.due_date_from)
                    : null
                }
                sx={{ my: 2, width: "100%" }}
              />
            </Box>
          </Box>
          <Box pb={4}>
            <Typography variant="subtitle1">Status</Typography>
            <Typography variant="body2">
              List transactions based on their status:
            </Typography>
            <Grid container gap={1} justifyContent={"start"}>
              <TransactionStatusChip
                label="All"
                onClick={clearStatus}
                active={
                  !formik.values.status &&
                  !formik.values.last_transaction_failed
                }
              />
              <TransactionStatusChip
                {...getStatusFieldProps({
                  status: "PAID",
                  lastTransactionFailed: false,
                })}
              />
              <TransactionStatusChip
                {...getStatusFieldProps({
                  status: "MANUALLY_SETTLED",
                  lastTransactionFailed: false,
                })}
              />
              <TransactionStatusChip
                {...getStatusFieldProps({
                  status: "PENDING",
                  lastTransactionFailed: false,
                })}
              />
              <TransactionStatusChip
                {...getStatusFieldProps({
                  status: "OVERDUE",
                  lastTransactionFailed: true,
                })}
              />
              <TransactionStatusChip
                {...getStatusFieldProps({
                  status: "PENDING",
                  lastTransactionFailed: true,
                })}
                label="Failed"
              />
            </Grid>
          </Box>
          <Box pb={4}>
            <Typography variant="subtitle1">Location name</Typography>
            <Typography variant="body2">
              List transactions for a given location:
            </Typography>
            <Autocomplete
              value={formik.values.location_name ?? null}
              options={props.locationNames}
              onChange={(_event, value) =>
                formik.setFieldValue("location_name", value)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="location_name"
                  onBlur={formik.handleBlur}
                  variant="outlined"
                  placeholder="Start typing"
                  fullWidth
                />
              )}
            />
          </Box>
          <Box pb={4}>
            <Typography variant="subtitle1">Customer number</Typography>
            <Typography variant="body2">
              List transactions for a given customer number:
            </Typography>
            <TextField
              name={"customer_number"}
              placeholder="Start typing"
              value={formik.values.customer_number}
              onChange={(event) => {
                formik.setFieldValue("customer_number", event.target.value);
              }}
              variant="outlined"
              fullWidth
            />
          </Box>
          <Grid container gap={1}>
            <Button variant={"contained"} color="info" onClick={clearForm}>
              Clear filters
            </Button>
            <Button type={"submit"} variant={"contained"} color="primary">
              {data === undefined
                ? "Loading..."
                : `Show ${data?.totalElements} ${
                    data?.totalElements === 1 ? "transaction" : "transactions"
                  }`}
            </Button>
          </Grid>
        </form>
      </Box>
    </ViewDialog>
  );
}
