import { MenuItem, Stack, TextField } from "@mui/material";
import { useFormik } from "formik";
import { unpackResponse, useClient } from "../../client";
import { Schema } from "../../client/types";
import { FormDialog } from "../../ui/form-dialog";
import { DialogState } from "../../ui/more-menu";
import { getTextFieldProps } from "../../ui/text-field";
import { caughtValueToString } from "../../utils/caught-error";

type FormValues = {
  label: string;
  addonId: number;
};

type Props = DialogState & {
  gatewayDeviceId: number;
  locationId: number;
  addons: Schema["Addon"][];
  revalidateView: () => void;
};

function DeviceGroupFormDialog(
  props: Props & {
    onSubmit: (values: Schema["CreateDeviceGroupRequest"]) => Promise<void>;
    deviceGroup?: Schema["DeviceGroup"];
    title: string;
    submitLabel: string;
  },
) {
  const { deviceGroup, addons } = props;

  const formik = useFormik<FormValues>({
    initialValues: {
      label: deviceGroup?.label ?? "",
      addonId: props.addons.at(0)!.id,
    },
    async onSubmit(values) {
      try {
        await props.onSubmit({
          ...values,
          parentDeviceId: props.gatewayDeviceId,
          locationId: props.locationId,
        });
        props.revalidateView();
        props.onClose();
      } catch (error) {
        formik.setStatus(caughtValueToString(error));
      }
    },
  });

  return (
    <FormDialog {...props} form={formik}>
      <Stack direction="column" gap={4}>
        <TextField
          {...getTextFieldProps(formik, "label")}
          variant="standard"
          fullWidth
          label="Label"
        />

        <TextField
          {...getTextFieldProps(formik, "addonId")}
          variant="standard"
          fullWidth
          label="Variant"
          select
        >
          {addons.map((addon) => {
            return (
              <MenuItem value={addon.id!}>
                {addon.serviceLabel} ({addon.tierOptionLabel})
              </MenuItem>
            );
          })}
        </TextField>
      </Stack>
    </FormDialog>
  );
}

export function CreateDeviceGroupDialog(props: Props) {
  const client = useClient();

  return (
    <DeviceGroupFormDialog
      {...props}
      title="Create Device Group"
      submitLabel="Create"
      onSubmit={async (values) => {
        await unpackResponse(client.POST("/device-groups", { body: values }));
      }}
    />
  );
}

export function UpdateDeviceGroupDialog(
  props: DialogState & {
    deviceGroup: Schema["DeviceGroup"];
    revalidateView: () => void;
  },
) {
  const { deviceGroup } = props;
  const client = useClient();

  const formik = useFormik({
    initialValues: {
      label: deviceGroup.label ?? "",
    },
    async onSubmit(values) {
      try {
        await unpackResponse(
          client.PATCH("/device-groups/{deviceGroupId}", {
            params: { path: { deviceGroupId: props.deviceGroup.id } },
            body: { label: values.label },
          }),
        );
        props.revalidateView();
        props.onClose();
      } catch (error) {
        formik.setStatus(caughtValueToString(error));
      }
    },
  });

  return (
    <FormDialog
      {...props}
      title="Update Device Group"
      submitLabel="Save"
      form={formik}
    >
      <Stack direction="column" gap={4}>
        <TextField
          {...getTextFieldProps(formik, "label")}
          variant="standard"
          fullWidth
          label="Label"
        />
      </Stack>
    </FormDialog>
  );
}
