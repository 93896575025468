import { Box, TextField } from "@mui/material";
import { useFormik } from "formik";
import { unpackResponse, useClient } from "../client";
import { Schema } from "../client/types";
import { countryFormatter, countryRequiresState } from "../constants/countries";
import {
  getStatesOrProvincesByCountry,
  stateFormatter,
} from "../constants/statesAndProvinces";
import { caughtValueToString } from "../utils/caught-error";
import { serializeAddress } from "../utils/serialize-address";
import { addressSchema } from "../utils/yup-validators";
import {
  AutocompleteSelectField,
  getAutocompleteSelectFieldProps,
} from "./autocomplete-select-field";
import { FormDialog } from "./form-dialog";
import { getTextFieldProps } from "./text-field";

export function EditLocationAddressDialog(props: {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  location: Pick<Schema["LocationBase"], "id" | "address">;
}) {
  const client = useClient();
  const { location } = props;
  const formik = useFormik<Schema["Address"]>({
    initialValues: location.address,
    validationSchema: addressSchema,
    async onSubmit(values) {
      try {
        await unpackResponse(
          client.PATCH("/locations/{locationId}", {
            params: { path: { locationId: props.location.id } },
            body: { address: serializeAddress(values) },
          }),
        );

        props.onClose();
        props.onSuccess();
      } catch (error) {
        formik.setStatus(caughtValueToString(error));
      }
    },
  });

  return (
    <FormDialog
      {...props}
      title="Edit location address"
      submitLabel="Save"
      form={formik}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <TextField
          {...getTextFieldProps(formik, "addressLineOne")}
          label="Address Line 1"
          variant="standard"
          fullWidth
        />
        <TextField
          {...getTextFieldProps(formik, "addressLineTwo")}
          label="Address Line 2"
          variant="standard"
          fullWidth
        />
        <Box sx={{ display: "grid", gridTemplateColumns: "4fr 1fr", gap: 6 }}>
          <TextField
            {...getTextFieldProps(formik, "city")}
            label="City"
            variant="standard"
            fullWidth
          />
          <TextField
            {...getTextFieldProps(formik, "zipCode")}
            label="Zip Code"
            variant="standard"
            fullWidth
          />
        </Box>
        {countryRequiresState(formik.values.country) && (
          <AutocompleteSelectField
            {...getAutocompleteSelectFieldProps(formik, "state")}
            options={getStatesOrProvincesByCountry(formik.values.country)}
            getOptionLabel={(option) => stateFormatter.format(option)}
            label="State"
          />
        )}
        <AutocompleteSelectField
          {...getAutocompleteSelectFieldProps(formik, "country")}
          label="Country"
          options={countryFormatter.enums}
          getOptionLabel={(option) => countryFormatter.format(option)}
        />
      </Box>
    </FormDialog>
  );
}
