import { Stack, TextField } from "@mui/material";
import { useFormik } from "formik";
import { unpackResponse, useClient } from "../../client";
import { Schema } from "../../client/types";
import { FormDialog } from "../../ui/form-dialog";
import { DialogState } from "../../ui/more-menu";
import { getTextFieldProps } from "../../ui/text-field";
import { caughtValueToString } from "../../utils/caught-error";

export function EnrollGatewayDeviceDialog(
  props: DialogState & {
    device: Schema["Device"];
    revalidateView: () => void;
  },
) {
  const client = useClient();
  const { device } = props;

  const formik = useFormik({
    initialValues: {
      endorsementKey: "",
      registrationId: "",
    },
    async onSubmit(values) {
      try {
        await unpackResponse(
          client.PUT("/devices/{deviceId}/enrollment", {
            body: values,
            params: { path: { deviceId: device.id! } },
          }),
        );
        props.revalidateView();
        props.onClose();
      } catch (error) {
        formik.setStatus(caughtValueToString(error));
      }
    },
  });

  return (
    <FormDialog
      {...props}
      title="Enroll device"
      submitLabel="Enroll"
      form={formik}
    >
      <Stack direction="column" gap={4}>
        <TextField
          {...getTextFieldProps(formik, "endorsementKey")}
          variant="standard"
          fullWidth
          label="Endorsment Key"
        />

        <TextField
          {...getTextFieldProps(formik, "registrationId")}
          variant="standard"
          fullWidth
          label="Registration ID"
          multiline
        />
      </Stack>
    </FormDialog>
  );
}
